import React, {useState, useEffect} from 'react'

import ChampFormulaireInscription from './ChampFormulaireInscription'
import SelectionCategorie from './SelectionCategorie'
import ChampMdp from './ChampMdp'

import { postJSON, fetchCoordonneesLieuxJSON } from '../assets/utilitaires'
import { BACK_URL } from '../assets/variables'

import { ConnexionAction } from '../store/actions/ConnexionAction'
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom'

const FormulaireInscription = (props) => {

    const navigate = useNavigate()

    const [champsManquant, setChampsManquant] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [utilisateurPublic, setUtilisateurPublic] = useState(false)

    const [nom, setNom] = useState("")
    const [prenom, setPrenom] = useState("")
    const [structure, setStructure] = useState("")
    const [email, setEmail] = useState("")
    const [confirmerEmail, setConfirmerEmail] = useState("")
    const [telephone, setTelephone] = useState("")
    const [categorie, setCategorie] = useState("")
    const [adresse, setAdresse] = useState("")
    const [ville, setVille] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [collecte, setCollecte] = useState(false)
    const [couture, setCouture] = useState(false)
    const [aide, setAide] = useState(false)
    const [challenge, setChallenge] = useState(false)
    const [mdp, setMdp] = useState("")

    const login = (email, mdp) => {
        
        let payload = {email: email, mdp: mdp}

        props.connexionAction(payload).then(result => {
        
            if (result.success) {
                setIsLoading(false)
                navigate("/engagement")
            }
        })
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      }


    const soumettre = () => {
        
        setIsLoading(true)

        let erreur = false

        let erreurNom
        if (nom == null || nom.trim() === "") {
            erreurNom = <div><br /> - Nom </div>
            erreur = true
        }

        let erreurEmail
        if (email == null || email.trim() === "" || !validateEmail(email)) {
            erreurEmail = <div><br /> - Email </div>
            erreur = true
        }

        let erreurConfirmerEmail
        if (email != confirmerEmail) {
            erreurConfirmerEmail = <div><br /> - La confirmation de l'email ne correspond pas à l'email entré </div>
            erreur = true 
        }

        let erreurCategorie
        if (categorie == null || categorie.trim() === "" || categorie === "vide") {
            erreurCategorie = <div><br /> - Categorie </div>
            erreur = true
        }

        let erreurMdp
        if (mdp == null || mdp.trim() === "") {
            erreurMdp = <div><br /> - Mot de passe </div>
            erreur = true
        }

        if (erreur) {
            setIsLoading(false)
            const erreurTotale = <div className="texteRouge">Veuillez compléter les champs suivant avant de soumettre votre inscription (une adresse email valide est obligatoire) :
                                        {erreurNom}
                                        {erreurEmail}
                                        {erreurConfirmerEmail}
                                        {erreurCategorie}
                                        {erreurMdp}
                                        <br /></div>
            setChampsManquant(erreurTotale)
        }
        else {
            
            setChampsManquant("")
            
            var formulaireComplet

            const adresseComplete = adresse + " " + ville + " " + codePostal

            // On ne demande une adresse que si l'utilisateur veut apparaitre sur la carte
            if (utilisateurPublic) {
                fetchCoordonneesLieuxJSON(adresseComplete).then( (result) => {

                    console.log(result)

                    if (result === "erreur") {
                        setIsLoading(false)
                        setChampsManquant(<div className="texteRouge"><br />L'adresse donnée n'a pas été trouvée. Merci de vérifier son exactitude<br /> Si ce message persiste après vérification, tentez une autre adresse proche de la votre</div>)
                        window.scrollTo(0, 0)
                        return
                    }
    
                    formulaireComplet = {
                        nom:nom,
                        prenom:prenom,
                        structure:structure,
                        email:email,
                        mdp:mdp,
                        telephone:telephone,
                        categorie:categorie,
                        isPublic:utilisateurPublic,
                        adresse:adresse,
                        adresse_latitude:result[0].lat,
                        adresse_longitude:result[0].lon,
                        ville:ville,
                        codePostal:codePostal,
                        collecte:collecte,
                        couture:couture,
                        aide:aide,
                        challenge:challenge
                    } 
                    
                    postJSON("POST", formulaireComplet, BACK_URL + "/inscription")
                    .then(resultat => {
                        login(email, mdp)
                    })
                  
                })
            }
            else {
                formulaireComplet = {
                    nom:nom,
                    prenom:prenom,
                    structure:structure,
                    email:email,
                    mdp:mdp,
                    telephone:telephone,
                    categorie:categorie,
                    isPublic:utilisateurPublic,
                    adresse:adresse,
                    ville:ville,
                    codePostal:codePostal,
                    collecte:collecte,
                    couture:couture,
                    aide:aide,
                    challenge:challenge
                } 
                postJSON("POST", formulaireComplet, BACK_URL + "/inscription")
                    .then(resultat => {
                        if (resultat.succes === "existence"){
                            setChampsManquant("Un utilisateur existe déjà avec cette adresse email")
                            setIsLoading(false)
                            return
                        }
                        else {
                            login(email, mdp)
                        }
                        
                    })
            }        
        }
    }

    const handleChangeCategorie = (event) => {
        setCategorie(event.target.value)
    }

    const handleChangeNom = (event) => {
        setNom(event.target.value)
    }

    const handleChangePrenom = (event) => {
        setPrenom(event.target.value)
    }

    const handleChangeStructure = (event) => {
        setStructure(event.target.value)
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleChangeConfirmerEmail = (event) => {
        setConfirmerEmail(event.target.value)
    }

    const handleChangeTelephone = (event) => {
        setTelephone(event.target.value)
    }

    const handleChangeAdresse = (event) => {
        setAdresse(event.target.value)
    }

    const handleChangeVille = (event) => {
        setVille(event.target.value)
    }

    const handleChangeCodePostal = (event) => {
        setCodePostal(event.target.value)
    }

    const handleChangeMdp = (event) => {
        setMdp(event.target.value)
    }
    
    const handleChangeCollecte = () => {
        setCollecte(!collecte)
    }

    const handleChangeCouture = () => {
        setCouture(!couture)
    }

    const handleChangeAide = () => {
        setAide(!aide)
    }

    const handleChangeChallenge = () => {
        setChallenge(!challenge)
    }

    const handleClickUtilisateurPublic = () => {
        setUtilisateurPublic(!utilisateurPublic)
    }

    return <form className="formulaire">
                <div className="interieurFormulaire">
                    <div>{champsManquant}</div>

                    <h3>
                        Créez un compte pour participer au challenge Couture 2023 !
                    </h3>

                    <SelectionCategorie id="categorie" champ={"Catégorie"} handleChange={handleChangeCategorie} />
                    <ChampFormulaireInscription id="nom" champ={"Nom"} handleChange={handleChangeNom} />
                    <ChampFormulaireInscription id="prenom" champ={"Prénom"} handleChange={handleChangePrenom} />
                    {categorie !== "citoyen" && categorie !== "" ? <ChampFormulaireInscription id="structure" champ={"Nom de votre " + categorie} handleChange={handleChangeStructure} /> : <></>}
                    <ChampFormulaireInscription id="numeroTelephone" champ={"Numéro de télephone"} handleChange={handleChangeTelephone} />
                    <ChampFormulaireInscription id="email" champ={"Email"} handleChange={handleChangeEmail}/>
                    <ChampFormulaireInscription id="confirmerEmail" champ={"Confirmer Email"} handleChange={handleChangeConfirmerEmail}/>
                    <ChampMdp id="mdp"champ={"Mot de passe"} handleChange={handleChangeMdp}/>
                    


                    <label htmlFor="checkboxPublic"> Je souhaite apparaître avec mes coordonnés sur la carte des bénévoles </label>
                    <input id="checkboxPublic" type="checkbox" onClick={handleClickUtilisateurPublic} />

                    {utilisateurPublic ? 

                        <div>

                            <br />
                            <br />
                            <h4>
                                Vous avez choisi d'apparaître sur la carte du challenge, merci de renseigner vos coordonnées
                            </h4>
                            <p>
                            Elles nous serviront à vous positionner sur une carte de France (nom-adresse-email). 
                            Cela vous permettra de vous réunir, vous regrouper entre participant(e)s au Challenge !! 
                            Et bien sûr, on pourra communiquer avec vous !!! 
                            </p>

                            <ChampFormulaireInscription id="adresse" champ={"Adresse (Numéro - Rue)"} handleChange={handleChangeAdresse}/>                           
                            <ChampFormulaireInscription id="ville" champ={"Ville"} handleChange={handleChangeVille}/>                         
                            <ChampFormulaireInscription id="codePostal" champ={"Code postal"} handleChange={handleChangeCodePostal}/>

                            <br />
                            <br />

                            <h4>
                                De quelle(s) manière(s) prévoyez-vous de vous engager (à titre indicatif uniquement) ?
                            </h4>

                            <br />
                            <label htmlFor="checkboxCollecte"> Collecter des Tshirts (EN SEPTEMBRE) (n'hésitez pas à déclarer votre point de collecte dans la rubrique "S'engager" sur notre site  une fois votre inscription terminée. Votre point apparaitra sur notre carte !) </label>
                            <input id="checkboxCollecte" type="checkbox" onClick={handleChangeCollecte} />
                            <br />
                            <br />
                            <label htmlFor="checkboxAide"> Aider à la réalisation des Bonnets Chimio (trier, découper etc..) </label>
                            <input id="checkboxAide" type="checkbox" onClick={handleChangeAide} />
                            <br />
                            <br />
                            <label htmlFor="checkboxCouture"> Coudre des Bonnets Chimio (durant le mois d'octobre) </label>
                            <input id="checkboxCouture" type="checkbox" onClick={handleChangeCouture} />
                            <br />
                            <br />
                            <label htmlFor="checkboxChallenge"> Organiser un événement "Challenge" près de chez vous </label>
                            <input id="checkboxChallenge" type="checkbox" onClick={handleChangeChallenge} />

                        </div>
                    
                    :<></>}

<                   br />
                    
                    <input className="bouton center-petit" type="button" value={isLoading ? "Inscription en cours" : "S'inscrire"} onClick={soumettre} />
                    
                    <br />

                </div>
            </form>
            
  }


const mapStateToProps = (state)=>{
    
    return {
        userDetails: state.userDetails,
    }
}

const mapDispatchToProps = {
    connexionAction:ConnexionAction
}


export default connect(mapStateToProps, mapDispatchToProps)(FormulaireInscription)