import React, { useState, useRef } from 'react'
import {EMAIL_PARTICIPANTS} from '../assets/variables'
import {postJSON} from '../assets/utilitaires'


const ContactAtelier = (props) => {

    const listeEmails = props.emails

    const [corpsEmail, setCorpsEmail] = useState("")
    const [emailOrganisateur, setEmailOrganisateur] = useState("")
    const [objetEmail, setObjetEmail] = useState("")

    const [visibiliteSucces, setVisibiliteSucces] = useState(false)

    const inputObjet = useRef()
    const inputCorps = useRef()
    const inputEmailOrganisateur = useRef()

    const handleChangeEmailOrganisateur = (event, valeur="") => {
        setEmailOrganisateur(event.target.value)
    }

    const handleChangeCorpsEmail = (event, valeur="") => {
        setCorpsEmail(event.target.value)
    }

    const handleChangeObjetEmail = (event, valeur="") => {
        setObjetEmail(event.target.value)
    }

    const envoyerEmail = () => {
        const objet = {
            emailOrganisateur:emailOrganisateur,
            corpsEmail:corpsEmail,
            objetEmail:objetEmail,
            listeEmails:listeEmails
        }

        postJSON("POST", objet, EMAIL_PARTICIPANTS).then( (resultat) => {
            if (resultat.success === "success") {
                setVisibiliteSucces(true)
                inputEmailOrganisateur.current.value = ""
                inputObjet.current.value = ""
                inputCorps.current.value = ""
            }
        })
    }

    return (
        <div>
            {visibiliteSucces ? 
            <div className="policeVerte"> 
                Message envoyé avec succès
            </div>
            :<></>}
            <input ref={inputEmailOrganisateur} className="center-moyen inputChamp champEmailObjet" type="text" placeholder="Email personnel de contact" onChange={handleChangeEmailOrganisateur} />
            <input ref={inputObjet} className="center-moyen inputChamp champEmailObjet" type="text" placeholder="Objet" onChange={handleChangeObjetEmail} />
            <textarea ref={inputCorps}  className="center-moyen inputChamp champEmailTexte" placeholder="Texte" id="email" name="email" rows="9" cols="70" onChange={handleChangeCorpsEmail} />
            <input className="bouton center-petit" type="button" value="Envoyer" onClick={envoyerEmail} />
        </div>
    )
}

export default ContactAtelier