import React, { useState, useEffect } from 'react'
import { FicheEngagement } from './FicheEngagement'
import { fetchCoordonneesLieuxJSON, postJSON } from '../assets/utilitaires'
import { BACK_URL } from '../assets/variables'

import { connect } from 'react-redux'

const Centre = (props) => {
    

    const [adresse, setAdresse] = useState("")
    const [ville, setVille] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [nom, setNom] = useState("")
    const [horaires, setHoraires] = useState("")
    const [contact, setContact] = useState("")
    const [telephone, setTelephone] = useState("")

    const handleChangeAdresse = (event) => {
        setAdresse(event.target.value)
    }

    const handleChangeVille = (event) => {
        setVille(event.target.value)
    }

    const handleChangeCodePostal = (event) => {
        setCodePostal(event.target.value)
    }

    const handleChangeNom = (event) => {
        setNom(event.target.value)
    }

    const handleChangeHoraires = (event) => {
        setHoraires(event.target.value)
    }

    const handleChangeContact = (event) => {
        setContact(event.target.value)
    }

    const handleChangeTelephone = (event) => {
        setTelephone(event.target.value)
    }


    const soumettre = () => {

        const adresseComplete = adresse + " " + ville + " " + codePostal
        fetchCoordonneesLieuxJSON(adresseComplete).then( (infosLieu) => {
            const latitude = infosLieu[0].lat
            const longitude = infosLieu[0].lon


            var dbCollecte = {
                adresse: adresse,
                ville:ville,
                codePostal:codePostal,
                nom:nom,
                adresse_latitude: latitude,
                adresse_longitude: longitude,
                horaires: horaires,
                contact:contact,
                telephone:telephone
            }

            
            postJSON("POST", dbCollecte, BACK_URL+"/centre")
                .then(resultat => {
                    
                })
        } )

        
        
        

    }

    return ( 
        <FicheEngagement>
            Centre de soins
            <br />           
            
            <label htmlFor="nom">
                Renseignez le nom du Centre
                <input type="text" id="nom" placeholder="Nom" onChange={handleChangeNom} />
            </label>

            <br />

            <label htmlFor="adresse">
                Renseignez l'adresse du centre
                <input type="text" id="adresse" placeholder="Adresse" onChange={handleChangeAdresse} />
            </label>
            <br />
            <label htmlFor="ville">
 
                <input className="" type="text" id="ville" placeholder="Ville" onChange={handleChangeVille} />
            </label>
            <br />
            <label htmlFor="codePostal">

                <input className="" type="text" id="codePostal" placeholder="Code postal" onChange={handleChangeCodePostal} />
            </label>

            <br />
            
            <label htmlFor="horaires">
                Renseignez les horaires du centre
                <input type="text" id="horaires" placeholder="Horaires" onChange={handleChangeHoraires} />
            </label>

            <br />
            
            <label htmlFor="contact">
                Renseignez le contact au sein de ce centre
                <input type="text" id="contact" placeholder="Personne à contacter" onChange={handleChangeContact} />
            </label>

            <br />

            <label htmlFor="telephone">
                Renseignez le numéro de téléphone du centre
                <input type="text" id="telephone" placeholder="Téléphone" onChange={handleChangeTelephone} />
            </label>

            <br />

            <input className="bouton" type="button" value="Valider l'engagement" onClick={soumettre} />
        </FicheEngagement>
    )
}

const mapStateToProps = (state)=>{
    
    return {
        userDetails: state.connexion.userDetails,
    }
}


export default connect(mapStateToProps, null)(Centre)