import {LOGIN_ACTION_KEY} from '../constantes'

import { postJSON } from '../../assets/utilitaires'
import { BACK_URL } from '../../assets/variables'

export const DeconnexionAction = (payload) => (dispatch, getState) => {

    return new Promise((resolve, reject) => {
    
        setTimeout(() => {

            const infos = {
                id:payload.id,
                email: payload.email,
                isAdmin: payload.isAdmin,
                nom:payload.nom
            }

            postJSON("POST", infos, BACK_URL + "/identification/deconnexion")
            .then(resultat => {
                localStorage.setItem("userDetails", JSON.stringify(infos))
                dispatch({ type: LOGIN_ACTION_KEY, payload: infos })
                resolve({success:true})
        })      
            
        }, 2000)


        

    })
}