import React, {useState, useEffect} from 'react'
import { postJSON, fetchCoordonneesLieuxJSON } from '../assets/utilitaires'
import { ENGAGEMENT 	 } from '../assets/variables'

export const ElementListeEngagements = (props) => {

    const [visibilite, setVisibilite] = useState("none")
    const [visibiliteSuppression, setVisibiliteSuppression] = useState("none")

    const [modification, setModification] = useState()
    const [visibiliteBoutonAnnuler, setVisibiliteBoutonAnnuler] = useState(false)
    const [visibiliteBoutonModifier, setVisibiliteBoutonModifier] = useState(true)

    const [erreur, setErreur] = useState("")
    
    const [erreurValeurPlaces, setErreurValeurPlaces] = useState(false)
    

    

    useEffect(() => {
        if (props.categorie === "collecte") {
            setModification({
                confidentialite:"",
                adresse:"",
                ville:"",
                codePostal:"",
                nom:"",
                horaires:""
            })
        }
    
        if (props.categorie === "couture") {

            if (props.engagement.decision === "rejoindre"
                    || props.engagement.decision === "seul") {
                setVisibiliteBoutonModifier(false)
            }

            setModification({
                decision:"",
                confidentialite:"",
                adresse:"",
                ville:"",
                codePostal:"",
                nom:"",
                horaires:"",
                places:""
            })
        }
    },[])
    

    const modifier = (event) => {
        if(visibilite === "none") {
            setVisibilite("inline-block")
        }
        if(visibilite === "inline-block") {
            setVisibilite("none")
        }
        setVisibiliteBoutonAnnuler(!visibiliteBoutonAnnuler)
        setErreur("")
        
    }

    const supprimer = (event) => {
        if(visibiliteSuppression === "none") {
            setVisibiliteSuppression("inline-block")
        }
        if(visibiliteSuppression === "inline-block") {
            setVisibiliteSuppression("none")
        }
    }

    const confirmationSuppression = (event) => {
        
    }

    const validationModification = (event) => {

        const adresseTemp = modification.adresse ? modification.adresse : props.engagement.adresse
        const villeTemp = modification.ville ? modification.ville : props.engagement.ville
        const codePostalTemp = modification.codePostal ? modification.codePostal : props.engagement.code_postal

        const adresseComplete = adresseTemp + " " + villeTemp + " " + codePostalTemp
        console.log(adresseComplete)
        fetchCoordonneesLieuxJSON(adresseComplete).then( (infosLieu) => {

            if (infosLieu === "erreur") {
                setErreur("Adresse invalide")
                return
            }
            setErreur("")

            var latitude = ""
            var longitude = ""

            if (modification.adresse != "") {
                latitude = infosLieu[0].lat
                longitude = infosLieu[0].lon
            }

            const categorie = props.categorie
            const id = props.id

            const dbUpdate = Object.assign(modification,{
                categorie:categorie,
                id: id,
                adresse_latitude: latitude,
                adresse_longitude: longitude,
            })
            
            postJSON("POST", dbUpdate, ENGAGEMENT + "/" + categorie + "/maj")
                .then(resultat => {
                    window.location.reload();
                    console.log("Resultat update", resultat)
                })
        } )
    }


        
    

    const handleChange = (event) => {
        
        if (props.categorie === "collecte") {
            
            if (event.target.name === "confidentialite") {
                setModification({
                    ...modification,
                    confidentialite:event.target.value
                })
            }
            else if (event.target.name === "adresse") {
                setModification({
                    ...modification,
                    adresse:event.target.value
                })
            }
            else if (event.target.name === "ville") {
                setModification({
                    ...modification,
                    ville:event.target.value
                })
            }
            else if (event.target.name === "codePostal") {
                setModification({
                    ...modification,
                    codePostal:event.target.value
                })
            }
            else if (event.target.name === "nom") {
                setModification({
                    ...modification,
                    nom:event.target.value
                })
            }
            else if (event.target.name === "horaires") {
                setModification({
                    ...modification,
                    horaires:event.target.value
                })
            }
        }

        if (props.categorie === "couture") {

            if (event.target.name === "decision") {
                setModification({
                    ...modification,
                    decision:event.target.value
                })
            }
            else if (event.target.name === "confidentialite") {
                setModification({
                    ...modification,
                    confidentialite:event.target.value
                })
            }
            else if (event.target.name === "adresse") {
                setModification({
                    ...modification,
                    adresse:event.target.value
                })
            }
            else if (event.target.name === "ville") {
                setModification({
                    ...modification,
                    ville:event.target.value
                })
            }
            else if (event.target.name === "codePostal") {
                setModification({
                    ...modification,
                    codePostal:event.target.value
                })
            }
            else if (event.target.name === "nom") {
                setModification({
                    ...modification,
                    nom:event.target.value
                })
            }
            else if (event.target.name === "horaires") {
                setModification({
                    ...modification,
                    horaires:event.target.value
                })
            }
            else if (event.target.name === "places") {

                if (!/(^[0-9]+$)/.test(event.target.value)) {
                    setErreurValeurPlaces(true)
                }
                else {
                    setErreurValeurPlaces(false)
                    setModification({
                        ...modification,
                        places:event.target.value
                    })
                }

                
            }
        }
        
    }

    let element = ""
    if (props.categorie === "collecte") {
        element = (
            <tr>
                <td>
                    {props.engagement.confidentialite} 
                    <select  style={{display:visibilite}} className="" id="confidentialite" name="confidentialite" onChange={handleChange} >
                        <option value=""></option>
                        <option value="publique">Publique</option>
                        <option value="privee">Privée</option>
                    </select>
                </td>
                <td>
                    {props.engagement.adresse} 
                    <input name="adresse" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                    {erreur ? "Adresse invalide" : ""}
                </td>
                <td>
                    {props.engagement.ville} 
                    <input name="ville" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                    {erreur ? "Adresse invalide" : ""}
                </td>
                <td>
                    {props.engagement.code_postal} 
                    <input name="codePostal" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                    {erreur ? "Adresse invalide" : ""}
                </td>
                <td>
                    {props.engagement.nom} 
                    <input name="nom" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                </td>
                <td>
                    {props.engagement.horaires} 
                    <input name="horaires" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                </td>
                <td>
                    <input type="button" value={!visibiliteBoutonAnnuler ? "Modifier" : "Annuler"} onClick={modifier} /> 
                    <input style={{display:visibilite}} value="Enregistrer" type="button" onClick={validationModification} />
                </td>
                <td>
                    <input type="button" value="Supprimer" onClick={supprimer} /> 
                    <p style={{display:visibiliteSuppression}}>êtes vous sûr ?</p> 
                    <input style={{display:visibiliteSuppression}} value="Oui" type="button" onClick={confirmationSuppression} /> 
                    <input style={{display:visibiliteSuppression}} value="Non" type="button" onClick={supprimer} />
                </td>
            </tr>
            
        )
    }

    if (props.categorie === "couture") {
        element = (
            <tr>
                <td>
                    {props.engagement.decision} 
                    <select style={{display:visibilite}} className="" name="decision" id="decision" onChange={handleChange} >
                        <option value="vide"></option>
                        <option value="seul">Seul(e)</option>
                        <option value="rejoindre">Rejoindre un atelier</option>
                        <option value="organiser">Organiser un atelier</option>
                    </select>
                </td>
                <td>
                    {props.engagement.confidentialite}
                    <select  style={{display:visibilite}} className="" id="confidentialite" name="confidentialite" onChange={handleChange} >
                        <option value=""></option>
                        <option value="publique">Publique</option>
                        <option value="privee">Privée</option>
                    </select>
                </td>
                <td>
                    {props.engagement.adresse} 
                    <input name="adresse" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                    {erreur ? "Adresse invalide" : ""}
                </td>
                <td>
                    {props.engagement.ville} 
                    <input name="ville" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                    {erreur ? "Adresse invalide" : ""}
                </td>
                <td>
                    {props.engagement.code_postal} 
                    <input name="codePostal" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                    {erreur ? "Adresse invalide" : ""}
                </td>
                <td>
                    {props.engagement.nom} 
                    <input name="nom" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                </td>
                <td>
                    {props.engagement.horaires} 
                    <input name="horaires" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                </td>
                <td>
                    {props.engagement.places}
                    {erreurValeurPlaces ? <div className="texteRouge">Merci de ne rentrer qu'une valeur numérique</div> : <></>}
                    <input name="places" style={{display:visibilite}} placeholder="Nouvelle valeur" type="text" onChange={handleChange} />
                </td>
                <td>
                    
                    {visibiliteBoutonModifier ?
                        <input type="button" value={!visibiliteBoutonAnnuler ? "Modifier" : "Annuler"} onClick={modifier} />
                    : <></>} 
                    <input style={{display:visibilite}} value="Enregistrer" type="button" onClick={validationModification} />
                </td>
                <td>
                <input type="button" value="Supprimer" onClick={supprimer} /> 
                    <p style={{display:visibiliteSuppression}}>êtes vous sûr ?</p> 
                    <input style={{display:visibiliteSuppression}} value="Oui" type="button" onClick={confirmationSuppression} /> 
                    <input style={{display:visibiliteSuppression}} value="Non" type="button" onClick={supprimer} />
                </td>
            </tr>
            
        )
    }

    return (
        element
    )
}