import React, {useState} from 'react'

export default function SelectionCategorie(props) {
   
   
   return (
        <div>
            <label>Êtes-vous...
                <br />
                <select className="select" name="categorie" id="categorie" onChange={props.handleChange}>
                    <option value="vide"></option>
                    <option value="citoyen">Un(e) citoyen(ne)</option>
                    <option value="association">Une association</option>
                    <option value="commune">Une commune</option>
                    <option value="entreprise">Une entreprise</option>
                    <option value="collectif">Un collectif</option>
                </select>
            </label>
        </div>
        )
}
