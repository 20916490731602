import { Outlet, Link, useLocation } from "react-router-dom"
import { DeconnexionAction } from '../store/actions/DeconnexionAction'
import { connect } from 'react-redux'
import { useEffect } from 'react'

import { checkSession } from '../assets/utilitaires'


import { useNavigate } from 'react-router-dom'

const Layout = (props) => {

  const navigate = useNavigate()
  const location = useLocation()

  const handleDeconnexion = (e) => {
      
    let payload = { 
      email:"",
      isAdmin:false,
      nom:"" 
    }
    props.deconnexionAction(payload).then(result => {
        
      if (result.success) {

        if (location.pathname === "/engagement"
              || location.pathname === "/monCompte"
              || location.pathname === "/administration" ) {
              
                navigate("/")
        }else {
          navigate(location.pathname)
        }
      }
  })
  }

  // On verifie si la session existe toujours sur le serveur
  useEffect( () => {

    checkSession().then((data => {
      
        if(!data.sessionExiste) {
          handleDeconnexion()
        }
    }))

  },[])

  return (
    <div className="conteneurBandeau">
      <nav className="bandeau">
        <p className="bandeau-element bandeau-titre">Inscription Challenge Mon Bonnet Rose</p>
        <div className="affichageTelephone"></div>
        <Link className="bandeau-element bouton-nav" to="/">Accueil</Link>
        <Link className="bandeau-element bouton-nav" to="/carte">Carte</Link>
        {props.userDetails.email ? <Link className="bandeau-element bouton-nav" to="/engagement">S'engager</Link> : <></>}
        {props.userDetails.isAdmin ? <Link className="bandeau-element bouton-nav" to="/administration">Administration</Link> : <></>}
        <Link className="bandeau-element bouton-nav" to="https://lechallenge.monbonnetrose.fr/"  target="_blank">Site officiel du challenge</Link>
        {props.userDetails.email ? 
          <div className="blocBandeauDroit">
          <Link className="bandeau-element bouton-nav petit-bouton-pile" to="/monCompte">Mon compte</Link>
            <Link className="bandeau-element bouton-nav petit-bouton-pile" value="Déconnexion" onClick={handleDeconnexion}>Déconnexion</Link>
          </div>
        : <></>}
        {!props.userDetails.email ? 
          <div className="blocBandeauDroit">
            <Link className="bandeau-element bouton-nav petit-bouton-pile" to="/inscription">Inscription</Link>
            <Link className="bandeau-element bouton-nav petit-bouton-pile" to="/connexion">Connexion</Link>
          </div>
        : <></>}
      </nav>

      <Outlet />
    </div>
  )
};

const mapStateToProps = (state)=>{
    
  return {
      userDetails: state.connexion.userDetails,
  }
}

const mapDispatchToProps = {
  deconnexionAction:DeconnexionAction
}


export default connect(mapStateToProps, mapDispatchToProps)(Layout)
