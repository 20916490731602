import React, {useEffect, useState} from 'react'
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet'
import { Icon } from 'leaflet'
import L from 'leaflet'
import { connect } from 'react-redux'

import { ElementPopupCarte } from './ElementPopupCarte'

import 'leaflet/dist/leaflet.css'

const CarteComposant = (props) => {

    // Encadrement de la France
    var latMax = 53
    var latMin = 41
    var lonMax = -5
    var lonMin = 10
    
    const [donneesAffichees, setDonneesAffichees] = useState(props.donnees)
    const [infosParticipation, setInfosParticipation] = useState([])
    const [listeMarqueurs, setListeMarqueurs] = useState([]) 

    const [latFinale, setLatFinale] = useState( (latMax + latMin)/2 )
    const [lonFinale, setLonFinale] = useState( (lonMax + lonMin)/2 )
    
    useEffect( () => {
        const infosAtelier = props.donnees.filter((donnee) => donnee.categorie === "infosParticipation")
        setInfosParticipation(infosAtelier)
        const listeLieux = props.donnees.filter((donnee) => donnee.categorie === "collecte" || donnee.categorie === "couture" || donnee.categorie === "centre" || donnee.categorie === "participant")
        setDonneesAffichees(listeLieux)
    },[props.donnees] )

    

    useEffect( () => {

        var listePos = []
        var listeClusters = []

        donneesAffichees.forEach( (lieu, index) => {

            const posString = lieu.adresse_latitude + ";" + lieu.adresse_longitude

            // On regarde si cette adresse est deja connue pour l'integrer a un cluster le cas echeant
            if (listePos.includes(posString)) {
                listeClusters[posString].push(lieu)
            }
            else {
                listeClusters[posString] = [lieu]
                listePos.push(posString)
            }            
            
        })

        var liste = []

        var listeIcones = []
        
        for (const [key, lieux] of Object.entries(listeClusters)) {

            listeIcones = [] 
            
            const pos = key.split(";")

            const lat = pos[0]
            const lon = pos[1]

            if (lat > latMax) {
                latMax = lat
            }

            else if (lat < latMin) {
                latMin = lat
            }

            if (lon > lonMax) {
                lonMax = lon
            }

            else if (lon < lonMin) {
                lonMin = lon
            }

            
            setLatFinale( (latMax + latMin)/2 )
            setLonFinale( (lonMax + lonMin)/2 )



            
            var atelierPublic = false

            var categorieTemp = ""
            var iconPath = ""

            const listePopups = lieux.map( (lieu, index) => {

                var listeParticipants = infosParticipation.filter((participation) => participation.id_couture_organisateur === lieu.id)

                if (lieu.categorie === "collecte") {
                    categorieTemp = "Point de collecte"
                    iconPath = '/iconeCarteMbr3.png'
                }
                if (lieu.categorie === "couture") {
                    categorieTemp = "Atelier de couture"
                    iconPath = '/iconeCarteMbr2.png'
                    
                    if (lieu.decision === "organiser" && lieu.confidentialite === "publique") {
                        atelierPublic = true
                    }
    
                }
                if (lieu.categorie === "centre") {
                    categorieTemp = "Centre de soin"
                    iconPath = '/iconeCarteMbr4.png'
                }
                if (lieu.categorie === "participant") {
    
                    const typeLiteral = {
                        asso:"Association",
                        commune:"Commune",
                        citoyen:"Citoyen",
                        entreprise:"Entreprise",
                        collectif:"Collectif",
                        uneassociation:"Association",
                        unecommune:"Commune",
                        uncitoyen:"Citoyen",
                        uneentreorise:"Entreprise",
                        uncollectif:"Collectif"
                    }
    
                    categorieTemp = typeLiteral[lieu.type_utilisateur.replace(/\s+/g, '').toLowerCase()]
                    iconPath = '/iconeCarteMbr1.png'
                }
    
                var icon = L.icon({
                    iconUrl: iconPath,
                    iconSize: [40, 46],
                    iconAnchor: [20, 46]
                });
                
                listeIcones.push(icon)

                return(
                        <div key={index}>
                            <ElementPopupCarte lieu={lieu} listeParticipants={listeParticipants} categorieTemp={categorieTemp} atelierPublic={atelierPublic} idUtilisateur={props.userDetails.id} email={props.userDetails.email} />
                        </div>
                    )
            })

            var iconeFinale 

            if (listeIcones.length === 1) {
                iconeFinale = listeIcones[0]
            }
            else {
                iconeFinale = L.icon({
                    iconUrl: '/iconeCarteMbr5.png',
                    iconSize: [40, 46],
                    iconAnchor: [20, 46]
                });
            }

            liste.push(
                <Marker position={pos} key={key} icon={iconeFinale}>
                    <Popup>
                        {listePopups}
                    </Popup>
                </Marker>
            )
            
        }
        setListeMarqueurs(liste)

    }, [donneesAffichees, infosParticipation])


    const classe = "map"

    if(props.className) {
        classe = "map " + props.className
    }
 
    return (
            <MapContainer className={classe} center={[latFinale, lonFinale]} zoom={6} scrollWheelZoom={true}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {listeMarqueurs}
            </MapContainer>
    )
}




const mapStateToProps = (state) => {
    
    return {
        userDetails: state.connexion.userDetails,
    }
}

export default connect(mapStateToProps)(CarteComposant)