import React, { useState, useEffect } from 'react'
import { FicheEngagement } from './FicheEngagement'
import { fetchCoordonneesLieuxJSON, postJSON } from '../assets/utilitaires'
import { BACK_URL } from '../assets/variables'

import { connect } from 'react-redux'

const DonneesPersonnelles = (props) => {

    console.log("Coucou je suis la collecte", props.collecte)

    return ( 
        <div>
                        <label>
                            <br />
                            <br /> Prénom &nbsp;
                            <input type="text" value={props.prenom} onChange={props.handleChangePrenom} readOnly={props.lectureSeule} />
                        </label>
                        <label>
                            <br />
                            <br /> Nom &nbsp;
                            <input type="text" value={props.nom} onChange={props.handleChangeNom} readOnly={props.lectureSeule} />
                        </label>
                        <label>
                            <br />
                            <br /> Catégorie &nbsp;
                            <input type="text" value={props.categorie} onChange={props.handleChangeCategorie} readOnly={props.lectureSeule} />
                        </label>
                        <label>
                            <br />
                            <br /> Structure &nbsp;
                            <input type="text" value={props.structure} onChange={props.handleChangeStructure} readOnly={props.lectureSeule} />
                        </label>
                        <label>
                            <br />
                            <br /> Numéro de téléphone &nbsp;
                            <input type="text" value={props.numeroTelephone} onChange={props.handleChangeNumeroTelephone} readOnly={props.lectureSeule} />
                        </label>
                        <label>
                            <br />
                            <br /> Email &nbsp;
                            <input type="text" value={props.email} onChange={props.handleChangeEmail} readOnly={props.lectureSeule} />
                        </label>

                        <br />
                        <br />

                        <label htmlFor="checkboxPublic"> Je souhaite apparaître avec mes coordonnés sur la carte des bénévoles </label>
                        <input id="checkboxPublic" type="checkbox" onClick={props.handleChangeIsPublic} value={props.isPublic} defaultChecked={props.isPublic} disabled={props.lectureSeule} />
                        
                        {
                            props.isPublic ?

                            <div>
                                <label>
                                    <br />
                                    <br /> Adresse &nbsp;
                                    <input type="text" value={props.adresse} onChange={props.handleChangeAdresse} readOnly={props.lectureSeule} />
                                </label>
                                <label>
                                    <br />
                                    <br /> Ville &nbsp;
                                    <input type="text" value={props.ville} onChange={props.handleChangeVille} readOnly={props.lectureSeule} />
                                </label>
                                <label>
                                    <br />
                                    <br /> Code postal &nbsp; 
                                    <input type="text" value={props.codePostal} onChange={props.handleChangeCodePostal} readOnly={props.lectureSeule} />
                                </label>
                                <br />
                                <br />
                                <label htmlFor="checkboxCollecte"> Collecter des Tshirts (EN SEPTEMBRE) (n'hésitez pas à déclarer votre point de collecte dans la rubrique "S'engager" sur notre site  une fois votre inscription terminée. Votre point apparaitra sur notre carte !) </label>
                                <input id="checkboxCollecte" type="checkbox" onClick={props.handleChangeCollecte} defaultChecked={props.collecte} value={props.collecte} disabled={props.lectureSeule} />
                                <br />
                                <br />
                                <label htmlFor="checkboxAide"> Aider à la réalisation des Bonnets Chimio (trier, découper etc..) </label>
                                <input id="checkboxAide" type="checkbox" onClick={props.handleChangeAide} defaultChecked={props.aide} value={props.couture} disabled={props.lectureSeule} />
                                <br />
                                <br />
                                <label htmlFor="checkboxCouture"> Coudre des Bonnets Chimio (durant le mois d'octobre) </label>
                                <input id="checkboxCouture" type="checkbox" onClick={props.handleChangeCouture} defaultChecked={props.couture} value={props.aide} disabled={props.lectureSeule} />
                                <br />
                                <br />
                                <label htmlFor="checkboxChallenge"> Organiser un événement "Challenge" près de chez vous </label>
                                <input id="checkboxChallenge" type="checkbox" onClick={props.handleChangeChallenge} defaultChecked={props.challenge} value={props.challenge} disabled={props.lectureSeule} />
                            </div>
                            : <></>
                        }
                        
                    </div>
    )
}

const mapStateToProps = (state)=>{
    
    return {
        userDetails: state.connexion.userDetails,
    }
}


export default connect(mapStateToProps, null)(DonneesPersonnelles)