import React, { useState, useEffect } from 'react'
import CarteComposant from '../components/CarteComposant'
import FiltreCarte from '../components/FiltreCarte'
import { getEngagementsPublics } from "../assets/utilitaires"

import 'leaflet/dist/leaflet.css';

const Carte = (props) => {

    
    const [donneesRecues, setDonneesRecues] = useState([])
    const [donneesCarte, setDonneesCarte] = useState([])

    
    useEffect( () => {
        getEngagementsPublics().then((data) => {
            setDonneesRecues(data)
            setDonneesCarte(data)   
                
         })
    },[] )

    useEffect( () => {
       
        setDonneesCarte(donneesRecues)           
         
    },[donneesRecues] )


    const testFiltreText = (filtre, lieu) => {

        const texte = filtre.texte.replace(/[^A-Za-z0-9]/g, '')
        if (texte) {

            const regex = new RegExp(texte.toLowerCase(), 'gm')

            const categorie = lieu.categorie ? lieu.categorie : ""
            const nom = lieu.nom ? lieu.nom : ""
            const adresse = lieu.adresse? lieu.adresse + " " + lieu.ville + " " + lieu.code_postal  : ""

            if ( regex.test(categorie.toLowerCase()) ||
                    regex.test(nom.toLowerCase())    ||
                    regex.test(adresse.toLowerCase())
                    ) {
                        return true
                    }
            else {
                return false
            }

        }
        else {
            return true
        }
    }


    const handleChangeFiltre = (filtre) => { 

        const listeTemp = donneesRecues.filter( (lieu) => {
           return ( ((filtre.collecte && lieu.categorie === "collecte")  || 
                    (filtre.couture && lieu.categorie === "couture")    || 
                    (filtre.centre && lieu.categorie === "centre")     || 
                    (filtre.participant && lieu.categorie === "participant") ||
                    (lieu.categorie === "infosParticipation")) &&
                    testFiltreText(filtre, lieu) )      
        } )

        setDonneesCarte(listeTemp)
    }

    return <div className="mapContainer">
                <FiltreCarte onChangeFiltre={handleChangeFiltre} />
                <CarteComposant donnees={donneesCarte} /> 
            </div>
}

export default Carte