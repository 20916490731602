import React, { useState, useEffect } from 'react'

const FiltreCarte = (props) => {

    const [filtreCollecte, setFiltreCollecte] = useState(true)
    const [filtreCouture, setFiltreCouture] = useState(true)
    const [filtreCentre, setFiltreCentre] = useState(true)  
    const [filtreParticipant, setFiltreParticipant] = useState(true)

    const [filtreTexte, setFiltreTexte] = useState("")

    var etatFiltre = {
        collecte:filtreCollecte,
        couture:filtreCouture,
        centre:filtreCentre,
        participant:filtreParticipant,
        texte:filtreTexte
    }

    const handleClickCollecte = (event) => {
        setFiltreCollecte(!filtreCollecte)
    }

    const handleClickCouture = (event) => {
        setFiltreCouture(!filtreCouture)
    }

    const handleClickCentre = (event) => {
        setFiltreCentre(!filtreCentre)
    }

    const handleClickParticipant = (event) => {
        setFiltreParticipant(!filtreParticipant)
    }

    const handleChange = (event) => {
        setFiltreTexte(event.target.value)
    }

    useEffect( () => {
        props.onChangeFiltre(etatFiltre)
    }, [filtreCollecte, filtreCouture, filtreCentre, filtreParticipant, filtreTexte])
    
    return (
        <div className="filtreCarte">

            Filtrer la carte : 

            <br />
            <br />

            <div className="blocFiltreTexte">

                <input id="filtreTexte" type="text" onChange={handleChange} placeholder="Ville, type d'événement..." defaultChecked />
            
            </div>
            
            <br />
            <br />
            <br />
            <br />
            
            

            <div className="blocsFiltresTypes">

                <div className="">
                    Catégories : 
                </div>
                <br />
                <br />

                <div className="element">
                    <img src="/iconeCarteMbr3.png" height="24" width="20" />
                    <label htmlFor="checkboxCollectes"> Collectes </label> 
                    <input id="checkboxCollectes" type="checkbox" onClick={handleClickCollecte} defaultChecked />
                </div>
                <br />

                <div className="element">
                    <img src="/iconeCarteMbr2.png" height="24" width="20" />
                    <label htmlFor="checkboxAteliers"> Ateliers de couture </label>
                    <input id="checkboxAteliers" type="checkbox" onClick={handleClickCouture} defaultChecked />
                </div>
                
                <br />
                    
                <div className="element">
                    <img src="/iconeCarteMbr4.png" height="24" width="20" />
                    <label htmlFor="checkboxCentres"> Centres de soins </label>
                    <input id="checkboxCentres" type="checkbox" onClick={handleClickCentre} defaultChecked />   
                </div>
                
                <br />

                <div className="element">    
                    <img src="/iconeCarteMbr1.png" height="24" width="20" />
                    <label htmlFor="checkboxParticipant"> Participants </label>
                    <input id="checkboxParticipant" type="checkbox" onClick={handleClickParticipant} defaultChecked />   
                </div>

                <br />

                <div className="element"> 
                    <img src="/iconeCarteMbr5.png" height="24" width="20" />
                    <label htmlFor="checkboxParticipant"> Multiples points d'intérêt </label>
                </div> 
            
            </div>
        
        
        </div>
    )
}

export default FiltreCarte