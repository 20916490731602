import {SUPPRESSION_TOTALE_URL, 
    ENGAGEMENTS, 
    ENGAGEMENTS_PUBLICS, 
    CHECK_SESSION, 
    PARTICIPANTS, 
    DETAILS_UTILISATEUR, 
    INSCRIPTION_ATELIER, 
    INFOS_PARTICIPANTS,
    COORDONNEES} from "./variables"

export const postJSON = (methode, donnees, url) => {
    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: methode,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(donnees),
            withCredentials:true,
            credentials:'include'
        };
        
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                resolve(data)})
    })
    
}

export const suppressionDonneesTotales = (donnees) => {
    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(donnees),
            withCredentials:true
        };
        
        fetch(SUPPRESSION_TOTALE_URL, requestOptions).then(data => resolve(data))

    })
    
}

export async function fetchCoordonneesLieuxJSON(adresse) {

    return new Promise((resolve, reject) => {

        const url = "https://geocode.maps.co/search?q=" + adresse.replace(" ", "+")
        const donnees = {url:url}
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(donnees),
            withCredentials:true,
            credentials:'include'
        };
        
        fetch(COORDONNEES, requestOptions)
            .then(response => response.json())
            .then(lieux => {

                if (lieux.length) {
                    resolve(lieux) 
                }
                else {
                    resolve("erreur")
                }
            })
    })
   
    /*
    const response = await fetch(url)
    console.log(response)
    const lieux = await response.json()

    if (lieux.length) {
        return lieux
    }
    else {
        return "erreur"
    }*/
    
  }

export const getEngagements = (emailUtilisateur) => {
    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email:emailUtilisateur}),
            withCredentials:true,
            credentials:'include'
        };

        fetch(ENGAGEMENTS, requestOptions)
        .then(response => response.json())
        .then(data => {
            resolve(data)})
    })
}

export const getInfosParticipants = (idCoutureOrganisateur) => {
    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idCoutureOrganisateur:idCoutureOrganisateur}),
            withCredentials:true,
            credentials:'include'
        };

        fetch(INFOS_PARTICIPANTS, requestOptions)
        .then(response => response.json())
        .then(data => {
            resolve(data)})
    })
}

export const getDetailsUtilisateur = (emailUtilisateur) => {
    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email:emailUtilisateur}),
            withCredentials:true,
            credentials:'include'
        };

        fetch(DETAILS_UTILISATEUR, requestOptions)
        .then(response => response.json())
        .then(data => {
            resolve(data)})
    })
}

export const getEngagementsPublics = () => {
    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: "GET",
            withCredentials:true,
            credentials:'include'
        };

        fetch(ENGAGEMENTS_PUBLICS, requestOptions)
        .then(response => response.json())
        .then(data => {
            resolve(data)})
    })
}

export const getNombreParticipant = () => {
    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: "GET",
            withCredentials:true,
            credentials:'include'
        };

        fetch(PARTICIPANTS, requestOptions)
        .then(response => response.json())
        .then(data => {
            resolve(data)})
    })
}

export const rejoindreAtelier = (idAtelier, idEngagement) => {

    const donnees = {
        idAtelier:idAtelier,
        idEngagement:idEngagement
    }

    console.log(donnees)
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(donnees),
            withCredentials:true,
            credentials:'include'
        };

        fetch(INSCRIPTION_ATELIER, requestOptions)
        .then(response => response.json())
        .then(data => {
            resolve(data)
        })
    })
}


export const checkSession = () => {

    return new Promise((resolve, reject) => {
        
        const requestOptions = {
            method: "GET",
            withCredentials:true,
            credentials:'include'
        };

        fetch(CHECK_SESSION, requestOptions)
        .then(response => response.json())
        .then(data => {
            resolve(data)
        })
    })
    

}



