import React, { useState } from 'react'
import { postJSON } from '../assets/utilitaires'
import { RESET_PASS } from '../assets/variables'

import FormulaireConnexion from '../components/FormulaireConnexion'

export const MdpOublie = (props) => {

    const [email, setEmail] = useState("")

    const handleChange = (event) => {
        setEmail(event.target.value)
    }

    const soumettre = () => {
        
        const objet = {
            email:email
        }

        postJSON("POST", objet, RESET_PASS) 
    }


    return(
        <div className="wrap">
            <div className="container">

                Entrez votre adresse email et si un compte avec cette adresse existe nous vous enverrons un nouveau mot de passe par email.
                <h2>Il est fortement recommandé de changer ce mot de passe le plus tôt possible</h2>
                <h3>Pour modifier votre mot de passe, rendez-vous dans l'onglet Mon Compte</h3>

                <label htmlFor="email"></label>
                <input type="text" className="center-moyen inputChamp" value={email} name="email" placeholder="Email" onChange={handleChange} required />
                <input type="button" value="Valider" className="bouton center-moyen" onClick={soumettre} />
                
            </div>
        </div>
            
    )
}
