import React, {useState, useEffect} from 'react'
import FormulaireInscription from './FormulaireInscription'
import CarteComposant from './CarteComposant'
import { Link } from "react-router-dom"

import {getNombreParticipant, getEngagementsPublics} from '../assets/utilitaires'

export const Main = () => {

    const [nombreUtilisateur, setNombreUtilisateur] = useState("")
    const [donneesCarteParticipant, setDonneesCarteParticipant] = useState([])
    const [donneesCarteCollecte, setDonneesCarteCollecte] = useState([])
    const [donneesCarteCouture, setDonneesCarteCouture] = useState([])
    const [donneesCarteCentre, setDonneesCarteCentre] = useState([])

    useEffect( () => {
        getNombreParticipant().then((data) => {
            setNombreUtilisateur(data.nombreUtilisateur)
        })

        getEngagementsPublics().then((data) => {
            
            setDonneesCarteParticipant(data.filter( (donnee) => {
                return ( (donnee.categorie === "participant")  )      
             } ))   
             setDonneesCarteCollecte(data.filter( (donnee) => {
                return ( (donnee.categorie === "collecte")  )      
             } )) 
             setDonneesCarteCouture(data.filter( (donnee) => {
                return ( (donnee.categorie === "couture")  )      
             } )) 
             setDonneesCarteCentre(data.filter( (donnee) => {
                return ( (donnee.categorie === "centre")  )      
             } ))    
         })

    },[])




    return (<div className="container">
                <div className="accueil">

                    <h1>
                        MON BONNET ROSE - LE CHALLENGE COUTURE
                    </h1>
                    
                    <br />
                    <br />
                    <br />

                    <p>
                        Bienvenue sur le site d'inscription au challenge Mon Bonnet Rose ! Vous retrouverez ici tout le nécessaire pour vous enregistrer et créer ou trouver un événement près de chez vous
                    </p>

                    <p>
                        Et bien sûr n'hésitez pas à consulter régulièrement <a href="https://lechallenge.monbonnetrose.fr/" target="_blank">notre site principal</a> pour être tenu au courant des dernières actualités de l'association et du challenge ;)
                    </p>

                    <div>

                    <br />
                    <br />
                    <br />                         

                        <ul className="listeAccueil" >
                            <li>
                                L'onglet <Link className="" to="/carte">Carte</Link> vous permet de trouver un point de collecte ou un atelier de couture près de chez vous. Cliquez sur un atelier de couture pour vous y inscrire
                            </li>
                            <br />
                            <li>
                                Les onglets <Link className="" to="/inscription">Inscription</Link> et <Link className="" to="/connexion">Connexion</Link> vous permettront respectivement de créer un compte pour participer au challenge et vous y connecter à votre prochaine visite
                            </li>
                            <br />
                            <li>
                                Une fois connecté(e) vous aurez également accès aux onglets <Link className="" to="/engagement">S'engager</Link>, qui vous permettra de déclarer des points de collecte ou d'organiser et gérer des ateliers de couture, et <Link className="" to="/monCompte">Mon Compte</Link> qui regroupe et vous permet de modifier vos engagements ainsi que modifier ou supprimer vos données personnelles
                            </li>
                        </ul>

                    </div>

                    <br />
                    <br />
                    <br />

                    <h2>
                        Le challenge Mon Bonnet Rose, c'est déjà {nombreUtilisateur} participants ! Rendez-vous dans l'onglet Inscription pour vous aussi nous rejoindre !
                    </h2> 

                    <br />
                    <br />
                    <br />

                    <h2>
                        Un énorme MERCI à vous toutes et tous ! 
                    </h2>

                    <img src="/banniere.jpg" />

                </div> 

            </div>)
}

/*

<div className="containerCarteAccueil">
                        <div className="petite-carte">
                            Participants au challenge
                            <CarteComposant className="map-petite" donnees={donneesCarteParticipant} /> 
                        </div>
                        <div className="petite-carte">
                            Points de collecte
                            <CarteComposant className="map-petite" donnees={donneesCarteCollecte} /> 
                        </div>
                        <div className="petite-carte">
                            Ateliers de couture
                            <CarteComposant className="map-petite" donnees={donneesCarteCouture} /> 
                        </div>
                        <div className="petite-carte">
                            Centres de soins
                            <CarteComposant className="map-petite" donnees={donneesCarteCentre} /> 
                        </div>
                    </div>

*/
