import React, {useState} from 'react'

import { BlocRejoindre } from './BlocRejoindre'


export const ElementPopupCarte = (props) => {

    const [visibiliteDetail, setVisibiliteDetail] = useState(true)

    const traductionBooleenne = (booleen) => {
        if (booleen) {
            return "Oui"
        }
        else {
            return "Non"
        }
    }

    const handleClickReduire = () => {
        setVisibiliteDetail(!visibiliteDetail)
    }

    

    const placesRestantes = parseInt(props.lieu.places) - props.listeParticipants.length

    const atelierPlein = (placesRestantes <= 0)

    var dejaInscrit = false
    props.listeParticipants.forEach( (participant) => {
        if (participant.id_utilisateur === props.idUtilisateur) {
            dejaInscrit = true
            return
        }
    })

    var utilisateurConnecte = false
    if (props.email != "") {
        utilisateurConnecte = true
    }

    

    return (
             <div>
                <hr />
                
                <input type="button" className='alignementDroit' value={visibiliteDetail ? "-" : "+"} onClick={handleClickReduire} />
                {"  "}
                <b>{props.categorieTemp}</b>
                <br />

                <h3>{props.lieu.prenom} {props.lieu.nom}</h3>
                <h2>{props.lieu.structure}</h2>
                

                {visibiliteDetail ? <div>
                    <br />
                    {props.lieu.adresse}, {props.lieu.ville}, {props.lieu.code_postal}
                    <br />                   
                    {props.lieu.categorie != "participant" ?  <div> <br /> Horaires : {props.lieu.horaires}</div> : <></>}
                    {props.lieu.categorie === "couture" ? 
                        <div> 
                            <br /> 
                            Nombre de places : {props.lieu.places}
                            <br /> 
                            Place(s) restante(s) : {placesRestantes}
                        </div> 
                    : <></>}
                    
                    {props.lieu.categorie === "participant" ?  
                        <div> 
                            <br />
                            <b>Intérêts : </b>
                            <br />
                            Collecte de T-shirts : {traductionBooleenne(props.lieu.collecte)}
                            <br />
                            Aide à la préparation des T-shirts : {traductionBooleenne(props.lieu.aide)}
                            <br />
                            Couture de bonnets : {traductionBooleenne(props.lieu.couture)}
                            <br />
                            Organisation d'un événement Challenge : {traductionBooleenne(props.lieu.challenge)}
                        </div> 
                    
                        : <></>}

                    
                    {props.lieu.categorie === "centre" ? <div> <br /> Contact sur place : {props.lieu.contact}</div> : <></>}        
                    {props.lieu.categorie === "centre" ? <div> <br /> Téléphone : {props.lieu.telephone}</div> : <></>}

                    <BlocRejoindre utilisateurConnecte={utilisateurConnecte} 
                                    atelierPublic={props.atelierPublic} 
                                    categorie={props.lieu.categorie} 
                                    dejaInscrit={dejaInscrit}
                                    atelierPlein={atelierPlein}
                                    lieuId={props.lieu.id}
                                    emailOrganisateur={props.email}
                                    adresse={props.lieu.adresse} 
                                    ville={props.lieu.ville} 
                                    codePostal={props.lieu.code_postal}
                                     />

                    <br />
                </div>
                :<></>}

            </div>
    )
}