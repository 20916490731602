import React, { useState } from 'react'
import { ConnexionAction } from '../store/actions/ConnexionAction'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

import FormulaireConnexion from '../components/FormulaireConnexion'

export const Connexion = (props) => {

    return(
        <div className="wrap">
            <div className="container">
                <FormulaireConnexion />
                <p className="accueil">
                    Pas encore inscrit ? <Link className="" to="/inscription">Cliquez ici</Link> pour créer un compte !
                </p>
                <a href="/mdpOublie">J'ai oublié mon mot de passe</a>
                
            </div>
        </div>
            
    )
}
