import { LOGIN_ACTION_KEY } from '../constantes'


const initial_state = {
    userDetails: {}
}

const ConnexionReducer = (state = initial_state, action) => {
    
    switch (action.type) {
    
        case LOGIN_ACTION_KEY:
            return { ...state, userDetails: { ...action.payload } }
        default:
            return state
}
}
export default ConnexionReducer