import { applyMiddleware,combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import ConnexionReducer from './reducers/ConnexionReducer'


const userInfoFromStorage = localStorage.getItem("userDetails")
  ? JSON.parse(localStorage.getItem("userDetails"))
  : {userDetails:{
      id:"",
      email:"",
      nom:"",
      isAdmin:0
  }}


const initialState = {
  connexion: { userDetails: userInfoFromStorage },
}


export const store = configureStore({
    reducer: {
        connexion: ConnexionReducer
    },
    preloadedState: initialState,
})