import React, { useState } from 'react'
import { ConnexionAction } from '../store/actions/ConnexionAction'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const FormulaireConnexion = (props) => {

    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [mdp, setMdp] = useState("")
    const [erreur, setErreur] = useState("")
    
    const login = (email, mdp) => {

        setErreur("")
        setIsLoading(true)
        
        let payload = {email: email, mdp: mdp }

        props.connexionAction(payload).then(resultat => {
        
            if (resultat.success) {
                setIsLoading(false)
                navigate("/engagement")
            }
            else {
                setIsLoading(false)
                if (resultat.result === "emailInconnu") {
                    setErreur("Aucun compte associé à cet email n'existe")
                }
                else if (resultat.result === "mdpIncorrect") {
                    setErreur("Le mot de passe est incorrect")
                }
                else {
                    setErreur("Une erreur est survenue, merci de rééssayer plus tard")
                }
                
            }
        })
    }

    const handleChange = (e) => {
        return (
            e.target.name === "email" ? setEmail(e.target.value):
            e.target.name == "mdp" ? setMdp(e.target.value):
            () => {}
        )
    }

    const handleClick = (e) => {
        e.preventDefault()
        email.length && mdp.length && login(email, mdp)
    }

    return(
        
            <div className="login">
                <h2>
                    Se connecter
                </h2>
                <form action="/" method="post">
                    <div className="container">
                        
                        <p className="erreur">{erreur}</p>

                        <label htmlFor="email"></label>
                        <input type="text" className="center-moyen inputChamp" value={email} name="email" placeholder="Email" onChange={handleChange} required />
                        
                        <br />

                        <label htmlFor="mdp"></label>
                        <input type="password" className="center-moyen inputChamp" value={mdp} name="mdp" placeholder="Mot de passe" onChange={handleChange} required />

                        <br />

                        <button className="bouton center-moyen" type="submit" onClick={handleClick}>
                            {!isLoading? "Se connecter":"Connexion en cours"}
                        </button>

                    </div>
                </form>
            </div>
    )
}


const mapStateToProps = (state)=>{
    
    return {
        userDetails: state.userDetails,
    }
}

const mapDispatchToProps = {
    connexionAction:ConnexionAction
}


export default connect(mapStateToProps, mapDispatchToProps)(FormulaireConnexion)