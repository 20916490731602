import logo from './logo.svg';
import './App.css';

import { connect } from 'react-redux'

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {Main} from './components/Main'
import Carte from './pages/Carte'
import Inscription from './pages/Inscription'
import Layout from './pages/Layout'
import NoPage from './pages/NoPage'
import Administration from './pages/Administration'
import {Connexion} from './pages/Connexion'
import MonCompte from './pages/MonCompte'
import Engagement from './pages/Engagement'
import {MdpOublie} from './pages/MdpOublie'

const ProtectedRoute = ({ user, children }) => {
  
  if (!user || !user.email) {
    return <Navigate to="/connexion" replace />
  }
  return children
}

const ProtectedRouteAdmin = ({ user, children }) => {
  
  if (!user.isAdmin) {
    return <Navigate to="/pasadmin" replace />
  }
  return children
}

const App = (props) => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>


        <Route index element={<Main />} />
      
        <Route path="carte" element={<Carte />} />

        <Route path="inscription" element={<Inscription />} />

        <Route path="administration" element={
          <ProtectedRouteAdmin user={props.userDetails}>
              <Administration />
          </ProtectedRouteAdmin>} />
        
        <Route path="pasadmin" element={<p>Vous n'êtes pas admin</p>} />
        
        <Route path="connexion" element={<Connexion />} />

        <Route path="mdpOublie" element={<MdpOublie />} />

        <Route path="engagement" element={
          <ProtectedRoute user={props.userDetails}>
            <Engagement />
          </ProtectedRoute>} />

        <Route path="monCompte" element={
          <ProtectedRoute user={props.userDetails}>
            <MonCompte />
          </ProtectedRoute>} />

        <Route path="*" element={<NoPage />} />


      </Route>
    </Routes>
  </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
    
  return {
      userDetails: state.connexion.userDetails,
  }
}

export default connect(mapStateToProps, null)(App)


