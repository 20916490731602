import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ChampMdp from '../components/ChampMdp'
import { DeconnexionAction } from '../store/actions/DeconnexionAction'
import { ElementListeEngagements } from '../components/ElementListeEngagements'

import { suppressionDonneesTotales, getEngagements, postJSON, getDetailsUtilisateur, fetchCoordonneesLieuxJSON } from "../assets/utilitaires"
import { CHANGE_PASS, MAJ_UTILISATEUR, MAJ_BONNETS_COUSUS } from '../assets/variables'
import DonneesPersonelles from '../components/DonneesPersonelles'


 const MonCompte = (props) => {

    const [visibiliteConfirmationSuppression, setVisibiliteConfirmationSuppression] = useState("none")
    const [messageConfirmationSuppression, setMessageConfirmationSuppression] = useState("")
    const [mdp ,setMdp] = useState("")

    const [mdpActuel, setMdpActuel] = useState("")
    const [mdpNouveau, setMdpNouveau] = useState("")

    const [listeEngagementsTotaux, setListeEngamementsTotaux] = useState("")

    const [lectureSeule, setLectureSeule] = useState(true)

    const [ prenom, setPrenom] = useState("")
    const [ nom, setNom] = useState("")
    const [ categorie, setCategorie] = useState("")
    const [ structure, setStructure] = useState("")
    const [ numeroTelephone, setNumeroTelephone] = useState("")
    const [ email, setEmail] = useState("")
    const [ isPublic, setIsPublic] = useState(undefined)
    const [ adresse, setAdresse] = useState("")
    const [ ville, setVille] = useState("")
    const [ codePostal, setCodePostal] = useState("")
    const [ collecte, setCollecte] = useState(undefined)
    const [ couture, setCouture] = useState(undefined)
    const [ aide, setAide] = useState(undefined)
    const [ challenge, setChallenge] = useState(undefined)

    const [messageValidation, setMessageValidation] = useState(false)

    const [couturier, setCouturier] = useState(false)
    const [nombreBonnetsCousus, setNombreBonnetsCousus] = useState("")
    const [nombreBonnetsCoususAffichage, setNombreBonnetsCoususAffichage] = useState("")

    

    const handleChange = (event) => {

        setMdp(event.target.value)

    }
    
    const suppressionDemandee = () => {
        setVisibiliteConfirmationSuppression("inline-block")
    }

    const annulationSuppression = () => {
        setVisibiliteConfirmationSuppression("none")
    }

    const confirmationSuppression = () => {
        
        const infos = {
            email:props.userDetails.email,
            mdp:mdp
        }

        suppressionDonneesTotales(infos)
            .then(resultat => {
                setMessageConfirmationSuppression("Vos données ont bien été supprimées")
                let payload = { email: "", mdp: "" }
            })
    }


    useEffect(() => {
        getEngagements(props.userDetails.email).then( (data) => {
            const listeEngagementsTotaux = data.map( (categorie, index) => {

                var cat = Object.keys(categorie)[0]

                const listeEngagementsParCategorie = categorie[cat].map( (engagement) => {

                    if (cat === "couture") {

                        if (engagement.decision === "organiser"
                                || engagement.decision === "seul") {
                                    setCouturier(true)
                                }

                    }
                    return (
                        <ElementListeEngagements key={engagement.id} id={engagement.id} categorie={Object.keys(categorie)[0]} engagement={engagement} />
                    )
                } )

                var entetes = ""
                var categorieFinale = ""

                if (cat === "collecte") {
                    categorieFinale = "Collecte(s) organisée(s)"
                    entetes = (
                        <tr>
                            <th>Confidentialité</th>
                            <th>Adresse</th>
                            <th>Ville</th>
                            <th>Code Postal</th>
                            <th>Nom</th>
                            <th>Horaires</th>
                            <th colSpan="2">Actions</th>
                        </tr>
                    )
                }

                if (cat === "couture") {

                    categorieFinale = "Atelier(s) de couture organisé(s)"
                    entetes = (
                        <tr>
                            <th>Décision</th>
                            <th>Confidentialité</th>
                            <th>Adresse</th>
                            <th>Ville</th>
                            <th>Code Postal</th>
                            <th>Nom</th>
                            <th>Horaires</th>
                            <th>Places</th>
                            <th colSpan="2">Actions</th>
                        </tr>
                    )
                }

                

                return (
                    <table key={index} className="tableau">
                        <caption>{categorieFinale}</caption>
                        <tbody>
                            {entetes}
                            {listeEngagementsParCategorie}

                        </tbody>
                    </table>
                )
            } )

            setListeEngamementsTotaux(listeEngagementsTotaux)

        })
        .then(
            getDetailsUtilisateur(props.userDetails.email).then( (data) => {
                
                const infos = data.resultat[0]
                
                const prenom    = infos.prenom ? infos.prenom : ""
                const nom       = infos.nom ? infos.nom : ""
                const categorie = infos.type_utilisateur ? infos.type_utilisateur : ""
                const structure   = infos.structure ? infos.structure : ""
                const numeroTelephone   = infos.numero_telephone ? infos.numero_telephone : ""
                const email    = infos.email ? infos.email : ""
                const isPublic = infos.isPublic ? true : false
                const adresse = infos.adresse ? infos.adresse : ""
                const ville = infos.ville ? infos.ville : ""
                const codePostal = infos.code_postal ? infos.code_postal : ""
                const collecte = infos.collecte ? true : false
                const couture = infos.couture ? true : false
                const aide = infos.aide ? true : false
                const challenge = infos.challenge ? true : false
                const nbrBonnets = infos.nombre_bonnets_cousus ? infos.nombre_bonnets_cousus : ""


                setPrenom(prenom)
                setNom(nom)
                setCategorie(categorie)
                setStructure(structure)
                setNumeroTelephone(numeroTelephone)
                setEmail(email)
                setIsPublic(isPublic)
                setAdresse(adresse)
                setVille(ville)
                setCodePostal(codePostal)
                setCollecte(collecte)
                setCouture(couture)
                setAide(aide)
                setChallenge(challenge)
                setNombreBonnetsCoususAffichage(nbrBonnets)
                
            } )
        )
        
    },[])



    const handleChangePrenom = (event) => {
        setPrenom(event.target.value)
    }
    const handleChangeNom = (event) => {
        setNom(event.target.value)
    }
    const handleChangeCategorie = (event) => {
        setCategorie(event.target.value)
    }
    const handleChangeStructure = (event) => {
        setStructure(event.target.value)
    }
    const handleChangeNumeroTelephone = (event) => {
        setNumeroTelephone(event.target.value)
    }
    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }
    const handleChangeIsPublic = (event) => {
        setIsPublic(!isPublic)
    }
    const handleChangeAdresse = (event) => {
        setAdresse(event.target.value)
    }
    const handleChangeVille = (event) => {
        setVille(event.target.value)
    }
    const handleChangeCodePostal = (event) => {
        setCodePostal(event.target.value)
    }
    const handleChangeCollecte = (event) => {
        setCollecte(!collecte)
    }
    const handleChangeCouture = (event) => {
        setCouture(!couture)
    }
    const handleChangeAide = (event) => {
        setAide(!aide)
    }
    const handleChangeChallenge = (event) => {
        setChallenge(!challenge)
    }



    const handleChangeMdpOrginal = (event) => {
        setMdpActuel(event.target.value)
    }

    const handleChangeNouveauMdp = (event) => {
        setMdpNouveau(event.target.value)
    }

    const demandeEditionInfosPerso = (event) => {
        setLectureSeule(!lectureSeule)
        setMessageValidation(false)
    }

    const handleChangeNombreBonnetsCousus = (event) => {
        setNombreBonnetsCousus(event.target.value)
    }

    useEffect( () => {
        console.log(lectureSeule)
    },[lectureSeule])


    const confirmationNouveauMdp = () => {
        const objet = {
            email:props.userDetails.email,
            mdpActuel:mdpActuel,
            mdpNouveau:mdpNouveau
        }

        postJSON("POST", objet, CHANGE_PASS)       
    }

    const confirmationNombreBonnetsCousus = () => {
        const objet = {
            nombreBonnets:nombreBonnetsCousus
        }

        postJSON("POST", objet, MAJ_BONNETS_COUSUS) .then( () =>{
            setNombreBonnetsCoususAffichage(nombreBonnetsCousus)
        })
    }


    const miseAJourDonneesPersonnelles = () => {
        
        if (adresse) {
            const adresseComplete = adresse + " " + ville + " " + codePostal
            fetchCoordonneesLieuxJSON(adresseComplete).then( (infosLieu) => {
                const objet = {
                    prenom:prenom,
                    nom:nom,
                    categorie:categorie,
                    structure:structure,
                    numeroTelephone:numeroTelephone,
                    email:email,
                    isPublic:isPublic,
                    adresse:adresse,
                    latitude:infosLieu[0].lat,
                    longitude:infosLieu[0].lon,
                    ville:ville,
                    codePostal:codePostal,
                    collecte:collecte,
                    couture:couture,
                    aide:aide,
                    challenge:challenge
                }
        
                postJSON("POST", objet, MAJ_UTILISATEUR).then( (data) => {
                    console.log("OK : ", data)
                    if (data.succes === "succes") {
                        setMessageValidation(true)
                        setLectureSeule(true)
                    }
                    return
                })
            })
        }
        else {
            const objet = {
                prenom:prenom,
                nom:nom,
                categorie:categorie,
                structure:structure,
                numeroTelephone:numeroTelephone,
                email:email,
                isPublic:isPublic,
                adresse:adresse,
                latitude:"",
                longitude:"",
                ville:ville,
                codePostal:codePostal,
                collecte:collecte,
                couture:couture,
                aide:aide,
                challenge:challenge
            }
    
            postJSON("POST", objet, MAJ_UTILISATEUR).then( (data) => {
                console.log("OK : ", data)
                if (data.succes === "succes") {
                    setMessageValidation(true)
                    setLectureSeule(true)
                }
                return
            })
        }
        
    }



    return (
        <div className="wrap">

            <h1>Bonjour {props.userDetails.nom} ! </h1>

            {couturier ? <div>
                <br />
                <br />
                <br />
                <br />
                
                <div className="element">
                    <b>Déclarer mon nombre total de bonnets cousus ou le nombre total de bonnets cousus par mon atelier</b>
                    <br />
                    <label>
                    <br /> <input type="text" className="center-moyen inputChamp" id="nombreBonnetsCousus" name="nombreBonnetsCousus" onChange={handleChangeNombreBonnetsCousus} placeholder="Nombre de bonnets cousus" />
                    </label>
                    <br />
                    <input className="bouton" type="button" value="Valider" onClick={confirmationNombreBonnetsCousus} />
                   {nombreBonnetsCoususAffichage ? <div><br /><br />Dernière déclaration : {nombreBonnetsCoususAffichage} bonnets cousus ! Bravo et merci !</div> :<></>}
                </div> 
            </div> : <></>}
            
            <div className="element">
                <b>Mes engagements</b>
                <br />
                <br />
                {listeEngagementsTotaux}
            </div>
            
            <br />
            <br />
            <br />
            <br />
            
            <div className="element">
                <b>Modifier mon mot de passe</b>
                <br />
                <ChampMdp id="mdpOriginal"champ={"Mot de passe actuel"} handleChange={handleChangeMdpOrginal}/>
                <ChampMdp id="mdpNouveau"champ={"Nouveau mot de passe"} handleChange={handleChangeNouveauMdp}/>
                <br />
                <input className="bouton" type="button" value="Valider" onClick={confirmationNouveauMdp} />
            </div>
            
            <br />
            <br />
            <br />
            <br />

            <div className="element">
                <b>Mes informations personnelles  </b>

                <br />
                <br />

                <h3>{messageValidation ? <b>Modifications enregistrées</b> : <></>}</h3>

                <DonneesPersonelles 
                    
                    prenom={prenom}
                    handleChangePrenom={handleChangePrenom}

                    nom={nom}
                    handleChangeNom={handleChangeNom}
                
                    categorie={categorie}
                    handleChangeCategorie={handleChangeCategorie}

                    structure={structure}
                    handleChangeStructure={handleChangeStructure}

                    numeroTelephone={numeroTelephone}
                    handleChangeNumeroTelephone={handleChangeNumeroTelephone}

                    email={email}
                    handleChangeEmail={handleChangeEmail}

                    isPublic={isPublic}
                    handleChangeIsPublic={handleChangeIsPublic}

                    adresse={adresse}
                    handleChangeAdresse={handleChangeAdresse}

                    ville={ville}
                    handleChangeVille={handleChangeVille}

                    codePostal={codePostal}
                    handleChangeCodePostal={handleChangeCodePostal}

                    collecte={collecte}
                    handleChangeCollecte={handleChangeCollecte}

                    couture={couture}
                    handleChangeCouture={handleChangeCouture}

                    aide={aide}
                    handleChangeAide={handleChangeAide}

                    challenge={challenge}
                    handleChangeChallenge={handleChangeChallenge}
        
                    lectureSeule={lectureSeule} 
                />

                <input type="button" className="bouton" value={lectureSeule ? "Modifier" : "Annuler"} onClick={demandeEditionInfosPerso} /> 
                <input className="bouton" style={{display:lectureSeule ? "none" : "inline-block"}} value="Enregistrer" type="button" onClick={miseAJourDonneesPersonnelles} />

            </div>

            <br />
            <br />
            <br />
            <br />
            
            <div className="element">
                <input className="bouton" type="button" value="Supprimer mes données" onClick={suppressionDemandee} />
                <div style={{display:visibiliteConfirmationSuppression}}>
                    Êtes-vous certain(e) de vouloir supprimer toutes vos données ? Cette action supprimera vos informations personnelles de nos serveurs mais également tout engagement que vous auriez pris, y compris les ateliers que vous organisez. Entrez votre mot de passe avasnt de cliquer sur Oui pour confirmer
                    <ChampMdp id="mdp"champ={"Mot de passe"} handleChange={handleChange}/>
                    <input className="bouton" type="button" value="Oui" onClick={confirmationSuppression} />
                    <input className="bouton" type="button" value="Non" onClick={annulationSuppression} />
                    {messageConfirmationSuppression}
                </div>   
            </div>      

            
        </div>
    )
 }

 const mapStateToProps = (state) => {
    
    return {
        userDetails: state.connexion.userDetails,
    }
}

const mapDispatchToProps = {
    deconnexionAction:DeconnexionAction
  }

export default connect(mapStateToProps, mapDispatchToProps)(MonCompte)