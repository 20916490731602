import React, { useState, useEffect } from 'react'
import { FicheEngagement } from '../FicheEngagement'
import { fetchCoordonneesLieuxJSON, postJSON } from '../../assets/utilitaires'
import { BACK_URL } from '../../assets/variables'

import { connect } from 'react-redux'



// Composant gérant l'enregistrement d'evenemts de collecte de t-shirts
const Collecte = (props) => {
        
    const [confidentialite, setConfidentialite] = useState("")
    const [adresse, setAdresse] = useState("")
    const [ville, setVille] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [horaires, setHoraires] = useState("")
    const [nom, setNom] = useState("")

    const [visibilite, setVisibilite] = useState("none")
    const [erreur, setErreur] = useState("")

    
    const handleChangeConfidentialite = (event) => {
        setConfidentialite(event.target.value)        
    }

    useEffect(() => {

        if (confidentialite === "publique") {
            setVisibilite("inline-block")
        }else {
            setVisibilite("none")
        }

    }, [confidentialite])


    const handleChangeAdresse = (event) => {
        setAdresse(event.target.value)
    }
    
    const handleChangeVille = (event) => {
        setVille(event.target.value)
    }

    const handleChangeCodePostal = (event) => {
        setCodePostal(event.target.value)
    }

    const handleChangeNom = (event) => {
        setNom(event.target.value)
    }

    const handleChangeHoraires = (event) => {
        setHoraires(event.target.value)
    }

    const soumettre = () => {

        if (confidentialite === "publique") {

            const adresseComplete = adresse + " " + ville + " " + codePostal
            console.log(adresseComplete)
            fetchCoordonneesLieuxJSON(adresseComplete).then( (infosLieu) => {

                if (infosLieu === "erreur") {
                    setErreur("Adresse invalide")
                    return
                }

                const latitude = infosLieu[0].lat
                const longitude = infosLieu[0].lon
    
    
                const dbCollecte = {
                    email_organisateur:props.userDetails.email,
                    confidentialite: confidentialite,
                    adresse: adresse,
                    ville:ville,
                    codePostal:codePostal,
                    nom : nom,
                    adresse_latitude: latitude,
                    adresse_longitude: longitude,
                    horaires: horaires
                }
    
                
                postJSON("POST", dbCollecte, BACK_URL+"/engagement/collecte")
                    .then(resultat => {
                        if (resultat != null) {
                            window.location.reload()
                        }
                    })
            } )
        }
        else {

            const dbCollecte = {
                email_organisateur:props.userDetails.email,
                confidentialite: confidentialite,
                adresse: "",
                adresse_latitude: 0,
                adresse_longitude: 0,
                horaires: ""
            }

            
            postJSON("POST", dbCollecte, BACK_URL+"/engagement/collecte")
                .then(resultat => {
                    if (resultat != null) {
                        window.location.reload()
                    }
                })
         
        }

        


    }

    return ( 
        <FicheEngagement>
            <h3>Collecter des Tshirts</h3>
            <br />
            <h4><i>Vous souhaitez aider à la collecte de tshirts pour le challenge</i></h4>
            <br />
            <h2>{erreur}</h2>
            <br />
            
            <label htmlFor="publiquePrivee">
                Votre collecte sera t-elle privée ou publique ? Notez qu'une collecte privée n'apparaîtra pas sur la carte du challenge
                <select className="select" id="publiquePrivee" onChange={handleChangeConfidentialite} >
                    <option value="vide"></option>
                    <option value="publique">Publique</option>
                    <option value="privee">Privée</option>
                </select>
            </label>
            
            <br />
            <br />

            <label style={{display:visibilite}} htmlFor="adresse">
                Renseignez l'adresse du point de votre collecte publique
                <input className="center-moyen inputChamp" type="text" id="adresse" placeholder="Adresse" onChange={handleChangeAdresse} />
            </label>
            <label style={{display:visibilite}} htmlFor="ville">
                <input className="center-moyen inputChamp" type="text" id="ville" placeholder="Ville" onChange={handleChangeVille} />
            </label>
            <label style={{display:visibilite}} htmlFor="codePostal">
                <input className="center-moyen inputChamp" type="text" id="codePostal" placeholder="Code postal" onChange={handleChangeCodePostal} />
            </label>

            <br />
            <br />

            <label style={{display:visibilite}} htmlFor="nom">
                Renseignez le nom du point de votre collecte si celui-ci est pertinent
                <input className="center-moyen inputChamp" type="text" id="nom" placeholder="Nom" onChange={handleChangeNom} />
            </label>

            <br />
            <br />
            
            <label style={{display:visibilite}} htmlFor="horaires">
                Renseignez les horaires de votre collecte publique
                <input className="center-moyen inputChamp" type="text" id="horaires" placeholder="Horaires" onChange={handleChangeHoraires} />
            </label>

            <br />
            <br />

            <input className="bouton center-moyen" type="button" value="Valider l'engagement" onClick={soumettre} />
        </FicheEngagement>
    )
}

const mapStateToProps = (state)=>{
    
    return {
        userDetails: state.connexion.userDetails,
    }
}


export default connect(mapStateToProps, null)(Collecte)