import React, { useState, useEffect, useRef } from 'react'

import Collecte from '../components/engagements/Collecte'
import Couture from '../components/engagements/Couture'
import Centre from '../components/Centre.js'

import * as XLSX from "xlsx"

import { postJSON } from '../assets/utilitaires'
import { IMPORT, RESET, NEW_ADMIN, MODIFIER_EMAIL, BACK_URL } from '../assets/variables'


const Administration = () => {

    const [items, setItems] = useState([])
    const [visibiliteResetBddUn, setVisibiliteResetBddUn] = useState(false)
    const [visibiliteResetBddDeux, setVisibiliteResetBddDeux] = useState(false)
    const [emailAdmin, setEmailAdmin] = useState("")

    const [emailUtilisateurModifie, setEmailUtilisateurModifie] = useState("")
    const [idUtilisateurModifie, setIdUtilisateurModifie] = useState("")
    const [validiteEmail, setValiditeEmail] = useState(false)
    const [succesModification, setSuccesModification] = useState(false)
    const inputId = useRef()
    const inputEmail = useRef()

    function exporter(categorie) {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            withCredentials:true,
            credentials:'include'
        };
        fetch('https://inscriptionchallenge.monbonnetrose.fr:8443/api/export/' + categorie, requestOptions)
            .then(response => response.json())
            .then(data => exportToCsv(data, categorie))
    }

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReader.readAsArrayBuffer(file)
    
          fileReader.onload = (e) => {
            const bufferArray = e.target.result
    
            const wb = XLSX.read(bufferArray, { type: "buffer" })
    
            const wsname = wb.SheetNames[0]
    
            const ws = wb.Sheets[wsname]
    
            const data = XLSX.utils.sheet_to_json(ws)
    
            resolve(data)
          }
    
          fileReader.onerror = (error) => {
            reject(error)
          }
        })
    
        promise.then((d) => {
          setItems(d)
        })
      }

    useEffect(() => {

        if (items.length > 0) {
            postJSON("POST", items, IMPORT)
            .then(resultat => {
            console.log("Resultat requete", resultat)
        })
        }
        
    },[items])

    function exportToCsv(lieuxData, categorie) {

        var headers
        var retourCsv

        if (categorie === "utilisateurs") {
            
            headers = ['Id, Nom, Numéro de téléphone, Email, Categorie, Adresse, Collecte, Aide à la préparation, Couture, Organisation challenge, Nombre bonnets cousus']

            retourCsv = lieuxData.reduce((accumulator, lieu) => {
                const lieuCsv = lieu.id + "," + 
                                (lieu.nom ? lieu.nom : " ") + "," + 
                                lieu.numero_telephone + "," + 
                                lieu.email + "," + 
                                lieu.type_utilisateur + "," + 
                                lieu.adresse + "," +
                                lieu.collecte + "," + 
                                lieu.aide + "," + 
                                lieu.couture + "," +  
                                lieu.challenge + "," +
                                lieu.nombre_bonnets_cousus + "\n"
                accumulator += lieuCsv
                return accumulator
            }, "")
        }
        else {
            headers = ['Id,Nom,Categorie,Latitude,Longitude']

            retourCsv = lieuxData.reduce((accumulator, lieu) => {
                const lieuCsv = lieu.id + "," + 
                                (lieu.nom ? lieu.nom : " ") + "," + 
                                lieu.categorie + "," + 
                                lieu.adresse_latitude + "," + 
                                lieu.adresse_longitude + "\n"
                accumulator += lieuCsv
                return accumulator
            }, "")
            
        }
        downloadFile({
            data: [...headers, retourCsv].join('\n'),
            fileName: categorie + '.csv',
            fileType: 'text/csv',
        })
    }

    function downloadFile({ data, fileName, fileType }) {
        const blob = new Blob([data], { type: fileType })
        
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        }



        const [affichageAjout, setAffichageAjout] = useState("")
        const afficherFormulaire = (choix) => {
            setAffichageAjout(choix)
        }


        const reinitialiserBDD = () => {
            postJSON("POST", {}, RESET)
            .then(resultat => {
             console.log("Resultat requete", resultat)
            })
        }

        const cliqueReset = () => {
            setVisibiliteResetBddUn(!visibiliteResetBddUn)
        }
        
        const confirmeReset = () => {
            setVisibiliteResetBddDeux(!visibiliteResetBddDeux)
        }

    const handleChangeAdmin = (event) => {
        setEmailAdmin(event.target.value)
    }

    const handleChangeModifEmailId = (event) => {
        setIdUtilisateurModifie(event.target.value)
    }

    const handleChangeModifEmailEmail = (event) => {
        setEmailUtilisateurModifie(event.target.value)
    }

    useEffect(() => {
        if (verifierEmail(emailUtilisateurModifie)) {
            setValiditeEmail(true)
        }
    }, [emailUtilisateurModifie])

    const verifierEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      }

    const modifierEmail = () => {
        const objet = {
            id:idUtilisateurModifie,
            email:emailUtilisateurModifie
        }
        postJSON("POST", objet, MODIFIER_EMAIL)
        .then(resultat => {
         console.log("Resultat requete", resultat)

         if (resultat.succes === "succes"){
            inputId.current.value = ""
            inputEmail.current.value = ""
            setValiditeEmail(false)
            setSuccesModification(true)
         }
        })
    }

    const gestionAdmin = (choix) => {
        const objet = {
            choix:choix,
            email:emailAdmin
        }
        postJSON("POST", objet, NEW_ADMIN)
        .then(resultat => {
         console.log("Resultat requete", resultat)
        })
    }

    return (
        <div>

            <div className="element">
                <h2>Outils d'exportation</h2>
                <br />
                <p>Utilisez ces boutons pour exporter les données en base au format CSV</p>
                <br />
                <input type="button" value="Exporter les lieux de collecte de T-Shirts" onClick={() => {exporter("collecte")}} />
                <input type="button" value="Exporter les ateliers de couture" onClick={() => {exporter("couture")}} />
                <input type="button" value="Exporter les centres de soins destinataires" onClick={() => {exporter("centre")}} />           
                <input type="button" value="Exporter les utilisateurs" onClick={() => {exporter("utilisateurs")}} />
            </div>
            
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="element">  
                <h2>Outils d'ajouts</h2>
                <br />
                <p>Ajoutez manuellement un centre de soin, point de collecte ou atelier de couture</p>
                <br />            
                <input type="button" value="Ajouter un centre de soin" onClick={() => {afficherFormulaire("centre")}} />
                <input type="button" value="Ajouter un point de collecte" onClick={() => {afficherFormulaire("collecte")}} />
                <input type="button" value="Ajouter un atelier de couture" onClick={() => {afficherFormulaire("couture")}} />
                { (affichageAjout === "centre") ? <Centre /> : <></>}
                { (affichageAjout === "collecte") ? <Collecte admin={true} /> : <></>}
                { (affichageAjout === "couture") ? <Couture admin={true}/> : <></>}
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="element"> 
                <h2>Outils d'importation</h2>     
                <br />  
                <p>Importer des utilisateurs depuis un classeur Excel</p>
                <br />
                <input type="file" onChange={(e) => {readExcel(e.target.files[0])} } />
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="element">

                <h2>Ajouter / Révoquer droits d'admin</h2>
                <br />
                Entrer l'email de l'utilisateur dont vous souhaitez modifier les droits puis cliquer sur le bouton correspondant
                <br />
                <input className="inputChamp" type="text" placeholder="email" onChange={handleChangeAdmin} />            
                <input type="button" value="Ajouter droits" onClick={() => {gestionAdmin("ajouter")}} /> 
                <input type="button" value="Révoquer droits" onClick={() => {gestionAdmin("revoquer")}} />
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="element">

                <h2>Modifier email utilisateur</h2>
                <br />
                Entrez l'identifiant de l'utilisateur dont vous souhaitez modifier l'email dans le champ "ID" et le nouvel email dans la case "Nouvel Email". Vous pouvez trouver l'identifiant dans le fichier Excel obtenu en exportant les utilisateurs
                <br />
                {succesModification ? <div className="texteVert">Email modifié avec succès</div> : <></>}
                <input className="inputChamp" ref={inputId} onChange={handleChangeModifEmailId} placeholder={"ID"} />    
                <input className="inputChamp" ref={inputEmail} onChange={handleChangeModifEmailEmail} placeholder={"Nouvel Email"} />    

                {validiteEmail ? <input type="button" value="Modifier Email" onClick={modifierEmail} /> : <div className="texteRouge">Entrez un email valide</div>}
                
                      
            </div>
            
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="element">
                <h2>Remise à zéro de la base de données</h2>
                <br />
                <p>Utilisez ce bouton à la fin du challenge pour remettre les données à zéro pour la prochaine édition</p>
                <br />
                <input type="button" value="Réinitialiser la base de données" onClick={cliqueReset} />
                {visibiliteResetBddUn ? 
                    <div>
                        Êtes-vous sûr(e) de bien vouloir effacer toutes les données ?
                        <input type="button" value="Oui" onClick={confirmeReset} />
                        <input type="button" value="Non" onClick={cliqueReset} />
                    </div> 
                : <></>}
                {visibiliteResetBddDeux ? 
                    <div>
                        Vraiment vraiment sûr(e) ?
                        <input type="button" value="Oui" onClick={reinitialiserBDD} />
                        <input type="button" value="Non" onClick={confirmeReset} />
                    </div> 
                : <></>}
            </div>

        </div>
    )
}

export default Administration;

