import React, { useState, useEffect } from 'react'
import { FicheEngagement } from '../FicheEngagement'
import { fetchCoordonneesLieuxJSON, postJSON } from '../../assets/utilitaires'
import { BACK_URL } from '../../assets/variables'

import { connect } from 'react-redux'

const Couture = (props) => {
    

    
    const [visibiliteOrganisation, setVisibiliteOrganisation] = useState("none")
    const [visibilitePublique, setVisibilitePublique] = useState("")

    const [decision, setDecision] = useState("")
    const [confidentialite, setConfidentialite] = useState("")
    const [adresse, setAdresse] = useState("")
    const [ville, setVille] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [nom, setNom] = useState("")
    const [horaires, setHoraires] = useState("")
    const [places, setPlaces] = useState("")

    const [erreur, setErreur] = useState("")
    const [erreurValeurPlaces, setErreurValeurPlaces] = useState(false)

    
    const handleChangeConfidentialite = (event) => {
        setConfidentialite(event.target.value)        
    }

    const handleChangeDecision = (event) => {
        setDecision(event.target.value)        
    }

    useEffect(() => {

        if (confidentialite === "publique") {
            setVisibilitePublique("inline-block")
        }else {
            setVisibilitePublique("none")
        }

    }, [confidentialite])

    useEffect(() => {

        if (decision === "organiser") {
            setVisibiliteOrganisation("inline-block")
        }else {
            setVisibiliteOrganisation("none")
        }

    }, [decision])


    const handleChangeAdresse = (event) => {
        setAdresse(event.target.value)
    }

    const handleChangeVille = (event) => {
        setVille(event.target.value)
    }

    const handleChangeCodePostal = (event) => {
        setCodePostal(event.target.value)
    }

    const handleChangeNom = (event) => {
        setNom(event.target.value)
    }

    const handleChangeHoraires = (event) => {
        setHoraires(event.target.value)
    }

    const handleChangePlaces = (event) => {
        console.log(event.target.value)
        if (!/(^[0-9]+$)/.test(event.target.value)) {
            console.log("if")
            setErreurValeurPlaces(true)
        }
        else {
            console.log("else")
            setErreurValeurPlaces(false)
            setPlaces(event.target.value)
        }
        
    }

    useEffect( () => {
        console.log("Places : ", places)
    }, [places])

    const soumettre = () => {

        if (!erreurValeurPlaces){
            if (confidentialite === "publique") {

                const adresseComplete = adresse + " " + ville + " " + codePostal
                fetchCoordonneesLieuxJSON(adresseComplete).then( (infosLieu) => {

                    if (infosLieu === "erreur") {
                        setErreur("Adresse invalide")
                        return
                    }

                    const latitude = infosLieu[0].lat
                    const longitude = infosLieu[0].lon
        
        
                    var dbCollecte = {
                        email_organisateur:props.userDetails.email,
                        decision: decision,
                        confidentialite: confidentialite,
                        adresse: adresse,
                        ville:ville,
                        codePostal:codePostal,
                        nom:nom,
                        adresse_latitude: latitude,
                        adresse_longitude: longitude,
                        horaires: horaires,
                        places: places
                    }
        
                    
                    postJSON("POST", dbCollecte, BACK_URL+"/engagement/couture")
                        .then(resultat => {
                            if (resultat != null) {
                                window.location.reload()
                            }
                        })
                } )
        
            }
            else {   
        
                    var dbCollecte = {
                        email_organisateur:props.userDetails.email,
                        decision: decision,
                        confidentialite: confidentialite,
                        adresse: "",
                        nom:"",
                        adresse_latitude: 0,
                        adresse_longitude: 0,
                        horaires: "",
                        places: ""
                    }
        
                    
                    postJSON("POST", dbCollecte, BACK_URL+"/engagement/couture")
                        .then(resultat => {
                            if (resultat != null) {
                                window.location.reload()
                            }
                        })
        
            }
        }

    }

    return ( 
        <FicheEngagement>
            <h3>Couture de bonnet</h3>
            <br />
            <h4><i>Vous souhaitez coudre des bonnets seul(e) ou en atelier</i></h4>
            <br />
            <h2>{erreur}</h2>
            <br />

            <label htmlFor="decision">
                Souhaitez vous coudre seul(e), en atelier ou organiser votre propre atelier ?
                <select className="select" id="decision" onChange={handleChangeDecision} >
                    <option value="vide"></option>
                    <option value="seul">Seul(e)</option>
                    <option value="rejoindre">Rejoindre un atelier</option>
                    <option value="organiser">Organiser un atelier</option>
                </select>
            </label>
            
            <br />
            <br />

            {decision === "rejoindre" ?

                <div>
                    Rendez-vous dans l'onglet Carte pour trouver et rejoindre un atelier près de chez vous :)
                </div>

            : <></>}

            <label style={{display:visibiliteOrganisation}} htmlFor="publiquePrivee">
                Votre atelier sera t-il public ou privé ? Un atelier privé n'apparaîtra pas sur la carte et ne pourra pas être rejoint depuis le site internet
                <select className="select" id="publiquePrivee" onChange={handleChangeConfidentialite} >
                    <option value="vide"></option>
                    <option value="publique">Public</option>
                    <option value="privee">Privé</option>
                </select>
            </label>
            
            <br />
            <br />
            
            <label style={{display:visibilitePublique}} htmlFor="adresse">
                Renseignez l'adresse de votre atelier
                <input className="center-moyen inputChamp" type="text" nom="adresse" id="adresse" placeholder="Adresse" onChange={handleChangeAdresse} />
            </label>
            <label style={{display:visibilitePublique}} htmlFor="ville">
                <input className="center-moyen inputChamp" type="text" nom="codePostal" id="ville" placeholder="Ville" onChange={handleChangeVille} />
            </label>
            <label style={{display:visibilitePublique}} htmlFor="codePostal">
                <input className="center-moyen inputChamp" type="text" nom="codePostal" id="codePostal" placeholder="Code postal" onChange={handleChangeCodePostal} />
            </label>

            <br />
            <br />
            
            <label style={{display:visibilitePublique}} htmlFor="nom">
                Renseignez le nom du lieu lorsque pertinent
                <input className="center-moyen inputChamp" type="text" nom="nom" id="nom" placeholder="Nom" onChange={handleChangeNom} />
            </label>

            <br />
            <br />
            
            <label style={{display:visibilitePublique}} htmlFor="horaires">
                Renseignez les horaires de votre atelier
                <input className="center-moyen inputChamp" type="text" nom="horaires" id="horaires" placeholder="Horaires" onChange={handleChangeHoraires} />
            </label>

            <br />
            <br />
            
            <label style={{display:visibilitePublique}} htmlFor="places">
                Combien de personnes votre atelier peut-il accueillir ?
                {erreurValeurPlaces ? <div className="texteRouge">Merci de ne rentrer qu'une valeur numérique</div> : <></>}
                <input className="center-moyen inputChamp" type="text" nom="places" id="places" placeholder="Nombre de places" onChange={handleChangePlaces} />
            </label>

            <br />
            <br />

            {decision !== "rejoindre" ?

                <input className="bouton center-moyen" type="button" value="Valider l'engagement" onClick={soumettre} />

            : <></>}
            
        </FicheEngagement>
    )
}

const mapStateToProps = (state)=>{
    
    return {
        userDetails: state.connexion.userDetails,
    }
}


export default connect(mapStateToProps, null)(Couture)