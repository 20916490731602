import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import engagements from '../engagements.json'
import {Ambassadeur} from '../components/engagements/Ambassadeur'
import Collecte from '../components/engagements/Collecte'
import Couture from '../components/engagements/Couture'
import ContactAtelier from '../components/ContactAtelier'


import {getEngagements, postJSON, getInfosParticipants} from "../assets/utilitaires"

const Engagement = (props) => {

    const [organisateurBool, setOrganisateurBool] = useState(false)

    var [gestionAtelier, setGestionAtelier] = useState([])

    var listeAteliersOrganises = []

    useEffect( () => {

        getEngagements(props.userDetails.email).then( (data => {
          
            data.forEach( async(categorie, index) => {

                var cat = Object.keys(categorie)[0]

                listeAteliersOrganises = await Promise.all(categorie[cat].map( async(engagement) => {

                    if (cat === "couture") {

                        if (engagement.decision === "organiser"
                                && engagement.confidentialite === "publique") {
                                    
                                    setOrganisateurBool(true)
                                    var detailsParticipants = await getInfosParticipants(engagement.id)
                                    console.log(engagement)
                                    console.log(detailsParticipants)
                                    
                                    var horaires = engagement.horaires

                                    const adresse = engagement.adresse + ", " + engagement.ville + ", " + engagement.code_postal

                                    var listeEmailsAtelier = []

                                    var participantsDisplay = detailsParticipants.map( (participant, index) => {

                                        listeEmailsAtelier.push(participant.email)
                                        return (
                                            <tr key={index}>
                                                <td>{participant.nom}</td>
                                                <td>{participant.email}</td>
                                            </tr>
                                        )
                                    })

                                    return(
                                        <div>
                                            <table>
                                                <caption>
                                                    Atelier du {horaires}
                                                    <br />
                                                    Adresse : {adresse}
                                                </caption>
                                                <tbody>
                                                    <tr>
                                                        <th>Nom - Prénom</th>
                                                        <th>Email</th>
                                                    </tr>
                                                    {participantsDisplay}
                                                </tbody>
                                            </table>
                                            <i>Pour envoyer un email groupé à tous les participants de l'atelier vous pouvez utiliser la zone de texte ci-dessous puis cliquer sur Envoyer</i>
                                            <ContactAtelier emails={listeEmailsAtelier} />
                                            <br />
                                            <br />
                                            <hr />
                                            <br />
                                            <br />
                                        </div>)

                                    //listeAteliersOrganises.push(ret)
                                    //console.log(listeAteliersOrganises)
                                    
                                }
                    }
                }))

                console.log(listeAteliersOrganises)
                setGestionAtelier(listeAteliersOrganises)
                
            })               

            

            
        }))
    
      },[])


      useEffect( () => {
        console.log(listeAteliersOrganises)
        console.log(gestionAtelier)
      }, [gestionAtelier])


   return (
    <div className="engagementsContainer">

     
       
        {organisateurBool ? 
        
        <div className="engagements">
            <br />
            <b>Mes ateliers organisés</b>
            <br />
            <br />
            <br />
            {gestionAtelier}
            <br />
        </div>
        
        : <></>}



       <div className="engagements">

           <h2><b>Comment souhaitez-vous vous engager dans le Challenge Mon Bonnet Rose ?</b></h2>
           <br />
            <Collecte className="fiche" admin={false} />
            <Couture className="fiche" admin={false} />
       </div>
    </div>
   )
}

const mapStateToProps = (state) => {
    
    return {
        userDetails: state.connexion.userDetails,
    }
}



export default connect(mapStateToProps)(Engagement)