export const categorie = "categorie"

export const BACK_URL = "https://inscriptionchallenge.monbonnetrose.fr:8443"

export const SUPPRESSION_TOTALE_URL = BACK_URL + "/suppressionTotale"

export const ENGAGEMENTS = BACK_URL + "/engagements"
export const ENGAGEMENTS_PUBLICS = BACK_URL + "/engagements/publics"

export const ENGAGEMENT = BACK_URL + "/engagement"
export const INSCRIPTION_ATELIER = BACK_URL + "/engagement/couture/rejoindre"
export const INFOS_PARTICIPANTS = BACK_URL + "/engagement/couture/infosParticipants"
export const EMAIL_PARTICIPANTS = BACK_URL + "/engagement/couture/emailParticipants"


export const CHECK_SESSION = BACK_URL + "/identification/checkSession"

export const CHANGE_PASS = BACK_URL + "/identification/changePass"

export const RESET_PASS = BACK_URL + "/identification/resetPass"

export const PARTICIPANTS = BACK_URL + "/api/users/nombre"
export const NEW_ADMIN = BACK_URL + "/api/users/admins"
export const DETAILS_UTILISATEUR = BACK_URL + "/api/users/details"
export const MAJ_UTILISATEUR = BACK_URL + "/api/users/maj"
export const MAJ_BONNETS_COUSUS = BACK_URL + "/api/users/nbrBonnets"
export const MODIFIER_EMAIL = BACK_URL + "/api/users/modifierEmail"


export const COORDONNEES = BACK_URL + "/api/lieux/geocode"

export const IMPORT = BACK_URL + "/api/export/import"

export const RESET = BACK_URL + "/resetBDD"



