import {LOGIN_ACTION_KEY} from '../constantes'
import { postJSON } from '../../assets/utilitaires'
import { BACK_URL } from '../../assets/variables'


export const ConnexionAction = (payload) => (dispatch, getState) => {

    return new Promise((resolve, reject) => {
        
        setTimeout(() => {

            const infos = {
                id:payload.id,
                email: payload.email,
                isAdmin: payload.isAdmin,
                mdp: payload.mdp
            }

            postJSON("POST", infos, BACK_URL + "/identification")
            .then(resultat => {
                console.log(resultat)
                if ( resultat.success ){
                    
                    console.log(resultat)
                    localStorage.setItem("userDetails", JSON.stringify(resultat.result))
                    dispatch({ type: LOGIN_ACTION_KEY, payload: resultat.result })
                    resolve({success:true})
                }
                else {
                    resolve(resultat)
                }
                
        })      
            
        }, 2000)
    })
}