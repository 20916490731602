import React, {useState, useEffect} from 'react'

import { rejoindreAtelier, postJSON } from '../assets/utilitaires'
import { BACK_URL } from '../assets/variables'


export const BlocRejoindre = (props) => {
    
    const [affichageConfirmation, setAffichageConfirmation] = useState(false)
    const [nouvelleInscription, setNouvelleInscription] = useState(false)

    const handleAffichageConfirmationRejoindre = () => {
        setAffichageConfirmation(!affichageConfirmation)
    }

    const handleRejoindre = (idAtelier) => {

        var dbCouture = {
            email_organisateur:props.emailOrganisateur,
            decision: "rejoindre",
            confidentialite: "",
            adresse: props.adresse,
            ville: props.ville,
            code_postal: props.codePostal,
            nom:"",
            adresse_latitude: 0,
            adresse_longitude: 0,
            horaires: "",
            places: ""
        }
        
        postJSON("POST", dbCouture, BACK_URL+"/engagement/couture")
            .then(resultat => {
                rejoindreAtelier(idAtelier, resultat.id).then(result => {
                    setNouvelleInscription(true)
                })
            })

        setAffichageConfirmation(!affichageConfirmation)
        
    }

    useEffect( () => {
        console.log(affichageConfirmation)
    }, [affichageConfirmation] )



    return (
        <div>
            {!props.utilisateurConnecte ?
                        <div>
                            Connectez vous pour vous rejoindre cet atelier
                        </div>
                    : <></>}

                    {props.atelierPublic && 
                        props.categorie === "couture" && 
                        !props.dejaInscrit && 
                        !nouvelleInscription && 
                        !props.atelierPlein &&
                        props.utilisateurConnecte ?
                            <div style={!affichageConfirmation ? {display:"inline"} : {display:"none"}}>
                                <br />
                                <input type="button" value="Rejoindre cet atelier" onClick={() => handleAffichageConfirmationRejoindre()} /> 
                            </div> 
                            
                    : <></>}     

                        <div style={affichageConfirmation ? {display:"block"} : {display:"none"}}>
                            <br />
                            Êtes-vous sûr de vouloir rejoindre cet atelier ?
                            <br />
                            <input type="button" value="Oui" onClick={() => handleRejoindre(props.lieuId)} /> 
                            <input type="button" value="Non" onClick={() => handleAffichageConfirmationRejoindre()} /> 
                        </div>

                    {props.atelierPlein ? 
                        <div className="texteRouge">
                            Cet atelier est plein :(
                        </div>
                    : <></>}
                    {nouvelleInscription ? 
                        <div className="texteVert">
                            Votre inscription a bien été prise en compte :)
                        </div>
                    : <></>}
                    {props.dejaInscrit ? 
                        <div className="texteVert">
                            Vous êtes déjà inscrit à cet atelier :)
                        </div>
                    : <></>}
        </div>
    )
}